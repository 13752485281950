//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import workprogress from "./barPhoto"; //上下班进度条
import workChart from "./workChart"; //上下班饼图
// import pieChart from "./progressPieChart"; //进程占比饼图
import timeLine from "./timeLine"; //时间轴
import XModal from "@/components/XModal";
import selTask from "@/components/Selectors/selTask";
import selMember from "@/components/Selectors/MemberSelectCopy";
import taskDetail from "@/userview/task-manager/task-modal"; //需要重写任务详情
// import selPro from "@/components/Selectors/selPro.vue";
// import Assessment from "@/views/assessment/Assessment.vue";
import XFroala from "@/components/XFroala.vue";
import comment from "@/components/List/Comment.vue";
import addKpi from "./addKpi";
import delayKpi from "./delayKPi";
import workAixs from "./workAixs";
import newProgess from "@/components/new_progress";
import highWords from "./highWords";
import tooltip from "@/components/textTooltip";
// import mapPage from "./mapPage";
import ScreenshotPic from "./screenshotPic";
import { mapState } from "vuex";
export default {
  components: {
    workprogress,
    workChart,
    XModal,
    selTask,
    selMember,
    taskDetail,
    // selPro,
    XFroala,
    // Assessment,
    comment,
    addKpi,
    delayKpi,
    workAixs,
    // pieChart,
    timeLine,
    newProgess,
    highWords,
    tooltip,
    // mapPage,
    ScreenshotPic,
  },
  props: {
    obj: {
      type: Object, //父级传过来的简报对象(时间，日期)
      default: function () {
        return {
          teamId: "",
          id: "",
          date: "",
        };
      },
    },
    detail: {
      //父级穿过来的简报信息
      type: Object,
    },
    isMember: {
      type: Boolean,
      dafault: false,
    },
  },
  data() {
    return {
      teamId: "",
      workStatusLoading: false,
      WorkType: 0, // 0：正常上班，1：加班
      selReportTaskId: null, //获取简报的某一个任务
      isApplyOverTime: false,
      keyPname: null, //选择的高频词窗口名
      picHeighWord: null, //选择的高频词
      loading: true,
      auto: false, //自动获取焦点
      workTimeInit: 0, //正常上班总时长初始值
      workTime: 0, //正常上班总时长
      overWorkTime: 0, //加班总时长
      progressOptions: [], //进度选项
      tomorrowPlan: "", //明日计划
      todayDiff: "", //今日困难
      id: "", //成员id
      day: "", //当前日期
      //客户相关
      addCustomerList: [], //增加客户列表
      dealCustomerList: [], //成交客户列表
      CRecordList: [],
      //今日已完成模块
      todayComplete: [], //今日已完成
      approvalArr: [], //正在审批中
      todayIndex: "", //今日已完成索引
      todayList: [], //今日完成任务部分渲染页面数组
      //今日计划模块
      todayPlanList: [], //今日计划列表渲染数据
      todayPlanListPer: [], //今日计划百分比列表
      //今日活动
      todayActivelist: [],
      //load接口返回对象
      loadData: {
        //获取简报详情 返回data
        Tickets: [], //客户  今日解决的可支持票
        active: [], //今日活动列表
        IsFirstEdit: "", //是否为第一次编辑简报
        prompt: null, //今日未打卡时 打开简报提示
        autoOverTimeAppliedOnlyUrgent: null, //审批简报  扣除除非常紧急后的加班时间
        IsAutoAudit: false, //是否通过自动审核
        be: {
          SkypeAndTeamViewer: {
            Skype: [],
            TeamViewer: [],
          },
          TaskCount: [],
          PostCount: [],
        },
        bu: {
          bid: null,
          Bl: {
            NextContent: "",
            NeedHelp: "",
          },
        },
        timeAxis: {
          //工作时间条
          overWork: [],
          work: [],
        },
        OverBws: {
          //加班时候工作占比
          OverEveBw: [],
          OverJobBw: [],
        },
        WorkBws: {
          //正常工作占比
          WorkEveBw: [],
          WorkJobBw: [],
        },
        jobs: [], //简报任务内容
        approvalReason: null, //简报驳回或者修改原因
        extraOverTimeList: [], //加班时间列表
        highWords: [], //今日高频词汇
      },
      selTask: [], //任务选择器所选任务
      appoptions: [
        //审核选项
        {
          value: 1,
          label: "以推荐时间为准",
        },
        {
          label: "以推荐时间为准（仅非常紧急的任务）",
          value: 4,
        },
        {
          label: "以填报时间为准",
          value: 2,
        },
        {
          label: "修改加班时间",
          value: 3,
        },
      ],
      appWay: "", //审核方法
      changeTime1: "", //状态三修改的时间
      reasons: "", //状态三输入的理由
      changeTime2: "", //状态四修改的时间
      feedArr: [
        {
          Link: "",
          Description: "",
        },
      ], //客户反馈
      inputReasons: "", //申请理由
      approverUser: [], //审核人
      actualApproverUser: [], //责任审批人
      visiblesUsers: [], //可见人
      // showExtraTips: false,
      startX: 0, //触摸位置
      endX: 0, //结束位置
      moveX: 0, //滑动时的位置
      disX: 0, //移动距离
      activeIndex: 0, //正常工作滑动索引
      activeOverIndex: 0, //加班滑动索引
      activeFeed: 0, //反馈索引
      deleteSlider: "", //滑动时的效果,使用v-bind:style="deleteSlider"
      activeSort: "", //任务选择器 简报类型
      activeSortIndex: "", //任务选择器 简报点击索引
      activeFatherSort: "", //任务选择器 父任务
      activeFatherSortIndex: "", //任务选择器  父任务
      activeProSort: "", //项目选择器
      activeProSortIndex: "", //项目选择器
      reportDetail: {},
      tipsTitle: "", //简报权限查看标题
      workChart_: "", //正常工作echart获得dom
      overWorkChart_: "", //加班工作echart获得dom
      userName: "", //用户名
      overTime_input: "", //加班时长
      referer: "", //当前页面路由
      taskItem: {},
      indexData: {},
      url: "",
      recordList: [], //分时时间轴
      normalWorkArr: [], //今日任务
      copyNormalWorkArr: [], //今日任务copy
      overWorkArr: [], //加班任务
      copyOverWorkArr: [], //加班任务copy
      activeTask: "",
      recommandOverTime: 0, //任务加班推荐时间
      //驳回
      rejectData: {
        reasontxt: "",
        resubmit: true,
      },
      reportMan: [], //简报人
      KpiData: {}, //创建kpi数据
      overTimereadOnly: true, //加班工时是否可输入
      // tipsArr: [],
      mgsArr: [],
      //提交时的参数
      normal: [],
      overWork: [],
      visibleArr: [],
      recordOverwork: false,
      isNowork: false, //明日是否无工作
      noticeUsers: [], //明日无工作要通知的人
      timeArr: [], //审批简报  修改加班时间列表
      selectOverTime: null,
      approvalProArr: [], //简报记录
      commentList: [], //评论列表
      bid: null, //简报id
      commentPage: {
        page: 1,
        count: 1,
      },
      commentDisabled: false,
      noMoreComment: true,
      rcoRatio: 0, //推荐加班时间比例
      tipsBox: {
        msg: "",
        data: "",
      },
      autoDistri: false,
      kpiType: 1,

      frontMan: {},
      dialogVisible: false,
      dialogImageUrl: "",
      // disabled: false,\
      activeSC: null, //简报截图
      activeReportType: null, //简报截图类型
      headerCellStyle: {
        //高频词表格
        textAlign: "center",
        fontSize: "18",
        background: "#f8f8f8",
        color: "#4a4a4a",
      },
    };
  },
  filters: {
    formatDate(date) {
      let _date = date.substr(0, 9);
      return _date;
    },
    formatHour(time) {
      if (!time) {
        return;
      }
      return time.substr(time.indexOf(":") - 2, 5);
    },
    formateDecimal(num) {
      if (!num) {
        return 0;
      }
      num = parseFloat(num).toFixed(2);
      let reg = /00/;
      if (reg.test(num)) {
        let i = num.indexOf(".");
        num = num.slice(0, i);
        return num;
      }
      if (num.length > 1 && num[num.length - 1] == 0) {
        num = num.slice(0, num.length - 1);
        return num;
      }
      return num;
    },
    formateDecimalTime(num) {
      if (!num) {
        return "无";
      }
      num = parseFloat(num).toFixed(2);
      let reg = /00/;
      if (reg.test(num)) {
        let i = num.indexOf(".");
        num = num.slice(0, i);
        return num + "小时";
      }
      if (num[num.length - 1] == 0) {
        num = num.slice(0, num.length - 1);
        return num + "小时";
      }
      return num + "小时";
    },
    formatMinutes(value) {
      if (!value) {
        return "无";
      }
      value = value * 60;
      var theTime = parseInt(value); // 秒
      var middle = 0; // 分
      var hour = 0; // 小时
      if (theTime >= 60) {
        middle = parseInt(theTime / 60);
        theTime = parseInt(theTime % 60);
        if (middle >= 60) {
          hour = parseInt(middle / 60);
          middle = parseInt(middle % 60);
        }
      }
      var result = "";
      if (middle > 0) {
        result = "" + parseInt(middle) + "分钟" + result;
      }
      if (hour > 0) {
        result = "" + parseInt(hour) + "小时" + result;
      }
      return result;
    },
    residualTargetFormat(val) {
      if (val > 0) {
        return `未完成：${val}`;
      } else if (val < 0) {
        return `超额完成：${0 - val}`;
      } else {
        return `未完成：0`;
      }
    },
  },
  computed: {
    ...mapState(["isAppTag"]),
    user() {
      return this.$store.state.user;
    },
  },
  watch: {
    loadData: {
      handler(old, newV) {},
      deep: true,
    },
    appWay() {},
    normalWorkArr() {
      this.workTime = 0;
      this.normalWorkArr.forEach((item) => {
        this.workTime += item.inputWasteTime * 1;
      });
    },
    overWorkArr() {
      this.recommandOverTime = 0;
      this.overTimereadOnly = true;
      this.overWorkArr.forEach((item) => {
        if (item.isApply) {
          this.overTimereadOnly = false;
          this.recommandOverTime += item.OverTime;
        }
      });
    },
    workTime() {},
    overWorkTime() {},
    activeIndex() {},
    detail() {},
    visiblesUsers() {
      //可见人
    },
    overTime_input() {
      if (isNaN(this.overTime_input)) {
        this.$notify({
          type: "info",
          message: "请输入合法数字",
        });
        this.overTime_input = "";
      }
    },
    changeTime1() {
      if (isNaN(this.changeTime1)) {
        this.$notify({
          type: "info",
          message: "请输入数字",
        });
        this.changeTime1 = "";
      }
    },
  },
  methods: {
    /**
     * 获取本周完成
     */
    getJobVariableSum(value, pid) {
      this.normalWorkArr.forEach((m, mind) => {
        if (m.JId == pid) {
          if (m.JobVariables && m.JobVariables.length) {
            m.JobVariables.forEach((s, sind) => {
              if (s.Type == 3 && s.JobVariableSum) {
                this.normalWorkArr[mind].JobVariables[sind].JobVariableSum =
                  parseInt(
                    this.normalWorkArr[mind].JobVariables[sind]
                      .JobVariableSumCopy
                  ) + parseInt(value);
                console.log(
                  this.normalWorkArr[mind].JobVariables[sind].JobVariableSum
                );
              }
            });
          }
        }
      });
      // this.overWorkArr.forEach((m, mind) => {
      //   if (m.JId == pid) {
      //     if (m.JobVariables && m.JobVariables.length) {
      //       m.JobVariables.forEach((s, sind) => {
      //         if (s.Type == 3 && s.JobVariableSum) {
      //           this.overWorkArr[mind].JobVariables[sind].JobVariableSum =
      //             parseInt(
      //               this.overWorkArr[mind].JobVariables[sind].JobVariableSumCopy
      //             ) + parseInt(value);
      //           console.log(
      //             this.overWorkArr[mind].JobVariables[sind].JobVariableSum
      //           );
      //         }
      //       });
      //     }
      //   }
      // });
    },
    /**
     * 关闭简报
     */
    closeReport(val) {
      if (this.isAppTag || this.$xStorage.getItem("isapp_tag")) {
        window.flutter_inappwebview.callHandler("closeAppReport", val);
      }
    },
    /**
     * 查看屏幕截图
     */
    handleScreenshot(val, type) {
      this.WorkType = type;
      this.selReportTaskId = val;
      this.$modal.show("screenshotPic");
    },
    /**
     * okr分数颜色
     */
    okrColor(val) {
      if (val < 60) {
        return "#F56C6C";
      } else if (val >= 60 && val < 90) {
        return "#E6A23C";
      } else if (val >= 90) {
        return "#67C23A";
      }
    },
    //加班申请步骤时间
    showDetail(time, index) {
      if (time) {
        return time.timeFormat("yyyy-MM-dd HH:mm");
      } else {
        return "--";
      }
    },
    //加班申请步骤颜色
    stepColor(sutaus) {
      if (sutaus == 4) {
        return "#67C23A";
      } else if (sutaus == 3) {
        return "#F56C6C";
      } else {
        return "";
      }
    },
    /**
     * 清除高频词选项
     */
    heighWordClear() {
      this.keyPname = null;
      this.picHeighWord = null;
    },
    /**
     * 点击高频词
     */
    handleHeighWord(val) {
      this.keyPname = val.FocusFormName;
      this.picHeighWord = val.Key;
      this.$modal.show("highWordsDes");
    },
    handleClose() {
      try {
        let bool = true;
        let normal = JSON.parse(this.copyNormalWorkArr);
        let overwork = JSON.parse(this.copyOverWorkArr);
        this.normalWorkArr.forEach((item, index) => {
          for (let key in item) {
            if (normal[index]) {
              if (
                JSON.stringify(item[key]) !== JSON.stringify(normal[index][key])
              ) {
                if (key == "fileList") {
                  item[key].forEach((img, imgIndex) => {
                    if (img.name !== normal[index][key][imgIndex].name) {
                      bool = false;
                    }
                    if (img.url !== normal[index][key][imgIndex].url) {
                      bool = false;
                    }
                    if (img.link !== normal[index][key][imgIndex].link) {
                      bool = false;
                    }
                  });
                } else {
                  bool = false;
                }
              }
            } else {
              bool = false;
            }
          }
        });
        this.overWorkArr.forEach((item, index) => {
          for (let key in item) {
            if (overwork[index]) {
              if (
                JSON.stringify(item[key]) !==
                JSON.stringify(overwork[index][key])
              ) {
                if (key == "fileList") {
                  item[key].forEach((img, imgIndex) => {
                    if (img.name !== overwork[index][key][imgIndex].name) {
                      bool = false;
                    }
                    if (img.url !== overwork[index][key][imgIndex].url) {
                      bool = false;
                    }
                    if (img.link !== overwork[index][key][imgIndex].link) {
                      bool = false;
                    }
                  });
                } else {
                  bool = false;
                }
              }
            } else {
              bool = false;
            }
          }
        });
        if (this.loadData.edit && !bool) {
          this.$confirm("当前页面内容发生改变，确认不提交并离开此页?", "", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.closeReport(false);
              this.$modal.hide("report");
            })
            .catch((err) => {});
        } else {
          this.closeReport(false);
          this.$modal.hide("report");
        }
      } catch (err) {
        //TODO handle the exception

        this.closeReport(false);
        this.$modal.hide("report");
      }
    },
    beforeRemove(file, fileList, index, type) {
      if (type == 0) {
        this.normalWorkArr[index].fileList = this.normalWorkArr[
          index
        ].fileList.filter((item) => {
          return item.link !== file.link;
        });
      } else {
        this.overWorkArr[index].fileList = this.overWorkArr[
          index
        ].fileList.filter((item) => {
          return item.link !== file.link;
        });
      }
    },
    scShoot({ val, index, type }) {
      this.activeSC = index;
      this.activeReportType = type;
      let params = {
        type: 3,
        hide: val == 1 ? true : false,
        npic: true,
      };
      this.$http
        .post("/Work/WrokAreas/NoticeUserInputScreenshots.ashx", params)
        .then();
    },
    composeValue(val, index, type) {
      return {
        val,
        index,
        type,
      };
    },
    imgUpload(res, file, fileList, index, type) {
      let arr = [];
      fileList.forEach((item) => {
        if (item.link) {
          arr.push({
            name: index,
            url: this.$url + item.link,
            link: item.link,
          });
        } else {
          arr.push({
            name: index,
            url: this.$url + item.response.link,
            link: item.response.link,
          });
        }
      });
      if (type == 0) {
        this.normalWorkArr[index].fileList = arr;
      } else {
        this.overWorkArr[index].fileList = arr;
      }
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    fileChange(file) {},
    clearReason() {
      this.inputReasons = "";
    },
    countNum(arr) {
      //客户总数
      let sum = 0;
      if (arr !== undefined) {
        arr.forEach((item) => {
          sum += item;
        });
      } else {
        sum = 0;
      }
      return sum;
    },
    showTaskDetail(item) {
      //显示任务详情页面
      this.taskItem = item;
      // this.url += this.$route.fullPath + "/" + item.JId;
      if (this.teamId && item.JId) {
        this.$modal.show("taskM");
      }
    },
    xmodalloadSuccess() {
      if (this.teamId && this.taskItem && this.taskItem.JId) {
        this.$modal.show("taskM");
      }
    },
    xmodalbeforeOpen() {
      var self = this;
      if (this.teamId && this.taskItem && this.taskItem.JId) {
        self.$refs.taskM.setLoading(true);
        this.taskLoading = true;
        this.$http
          .post("/Task/GetTaskDetail.ashx", {
            teamId: JSON.parse(this.teamId),
            Id: JSON.parse(this.taskItem.JId),
          })
          .then((resp) => {
            if (resp.res == 0) {
              self.indexData = {
                type: `${self.isMember ? "" : "Edit"}`,
                name: `${self.isMember ? "任务详情" : "编辑任务"}`,
                departmentCode: self.taskItem.JId,
                row: resp.data,
                xModalName: "taskM",
              };
              self.$modal.show("taskM");
              self.$nextTick(() => {
                self.$refs.taskM.changeEditState();
              });
            }
          })
          .finally(() => {
            self.$refs.taskM.setLoading(false);
          });
      }
    },
    checkStatu() {
      //切换完成和审批任务
      if (this.todayIndex == 0) {
        this.todayIndex = 1;
        this.todayList = this.approvalArr;
      } else {
        this.todayIndex = 0;
        this.todayList = this.todayComplete;
      }
    },
    updateProgress(index, data, target) {
      //修改今日计划进度条
      if (isNaN(data)) {
        this.todayPlanList[index].inputValue = 0;
        return 0;
      }
      if (data >= target && target != 0) {
        this.$set(this.todayPlanList[index], "Complete", target);
      }
      if (target == 0) {
        return 0;
      }
      this.$set(this.todayPlanList[index], "Complete", data);
      this.$set(
        this.todayPlanList[index],
        "per",
        parseFloat(((data / target) * 100).toFixed(2))
      );
    },
    addOneMoreNormalWork() {
      //增加一条正常工作记录
      this.normalWorkArr.push({
        Eve: null,
        JId: 0,
        JProgress: "0%",
        JSpentTime: 0,
        JFir: null,
        JContent: null,
        taskName: null,
        inputWasteTime: null,
        OverTime: 0,
        JEstimatedTime: 0,
        JTakeTime: 0,
        JShape: 1,
        IncreaseProgress: 0,
        JTimeProgress: 0,
        JName: null,
        edit: true,
        editJob: true,
        Progress: 0,
        IsOverWork: false,
        OverTime: 0,
        IsWorkArea: false,
        IsTempTask: false,
        PntTaskId: "",
        PntTaskName: "",
        visible: false,
        editJob: true,
        isApply: false,
        BwId: null,
        NextProgress: 100,
        fileList: [],
      });
      this.$nextTick(() => {
        this.$refs.normal[this.normalWorkArr.length - 1].scrollIntoView({
          behavior: "smooth",
        });
      });
    },
    removeNormalwork(index) {
      //删除一条正常工作记录
      this.$confirm("是否删除该条工作记录?", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.normalWorkArr.splice(index, 1);
        })
        .catch((err) => {});
    },

    selectJobTask(type, index, edit, editJob) {
      //选择任务
      if (!(edit && editJob)) {
        return;
      }
      this.activeTask = 0;
      this.$modal.show("chooseTask");
      if (type == 0) {
        //正常工作
        this.activeSort = 0;
        this.activeSortIndex = index;
      }
      if (type == 1) {
        //加班工作
        this.activeSort = 1;
        this.activeSortIndex = index;
      }
      if (type == 2) {
        //空闲中的工作
        this.activeSort = 2;
        this.activeSortIndex = index;
      }
      if (type == 3) {
        //空闲中的加班
        this.activeSort = 3;
        this.activeSortIndex = index;
      }
    },
    deleteTask(type, index) {
      //删除任务
      if (type == 0) {
        this.normalWorkArr[index].Eve = null;
        this.normalWorkArr[index].JId = 0;
        this.normalWorkArr[index].JContent = null;
        this.normalWorkArr[index].taskName = null;
        this.normalWorkArr[index].JProgress = "0%";
        this.normalWorkArr[index].Progress = 0;
        this.normalWorkArr[index].JEstimatedTime = 0;
        this.normalWorkArr[index].JFir = 0;
        this.normalWorkArr[index].JName = null;
        this.normalWorkArr[index].JTakeTime = 0;
      }
      if (type == 1) {
        this.overWorkArr[index].Eve = null;
        this.overWorkArr[index].JId = 0;
        this.overWorkArr[index].JContent = null;
        this.overWorkArr[index].taskName = null;
        this.overWorkArr[index].JProgress = "0%";
        this.overWorkArr[index].Progress = 0;
        this.overWorkArr[index].JEstimatedTime = 0;
        this.overWorkArr[index].JFir = 0;
        this.overWorkArr[index].JName = null;
        this.overWorkArr[index].JTakeTime = 0;
      }
    },
    deleteProject(type, index) {
      this.normalWorkArr.forEach((item) => {
        item.visible = false;
      });
      this.overWorkArr.forEach((item) => {
        item.visible = false;
      });
      if (type == 0) {
        this.normalWorkArr[index].Eve = null;
        this.normalWorkArr[index].PntTaskId = "";
      }
      if (type == 1) {
        this.overWorkArr[index].Eve = null;
        this.overWorkArr[index].PntTaskId = "";
      }
    },
    getTaskList(arr) {
      if (arr.length) {
        if (this.activeTask == 0) {
          if (this.activeSort == 0) {
            //正常简报
            // if(arr[0].JId)
            let bool = false;
            this.normalWorkArr.forEach((item) => {
              if (item.JId == arr[0].JId) {
                this.$message({
                  type: "warning",
                  message: "本日任务中已存在此任务，可直接修改该任务耗时",
                });
                bool = true;
                return;
              }
            });
            if (bool) {
              return;
            }

            this.normalWorkArr[this.activeSortIndex].Eve = {
              EId: arr[0].EventeID,
              EName: arr[0].EventeName,
              EFir: arr[0].EventeFir,
              EShape: 1,
            };

            this.normalWorkArr[this.activeSortIndex].JId = arr[0].JId;
            this.normalWorkArr[this.activeSortIndex].JProgress =
              arr[0].PreProgres + "%";
            this.normalWorkArr[this.activeSortIndex].Progress =
              arr[0].PreProgres;
            this.normalWorkArr[this.activeSortIndex].JEstimatedTime =
              arr[0].EstimatedTime;
            this.normalWorkArr[this.activeSortIndex].JFir = arr[0].Fir;
            this.normalWorkArr[this.activeSortIndex].JName = arr[0].Name;
            this.normalWorkArr[this.activeSortIndex].JTakeTime =
              this.formateInputDecimal(
                parseFloat(arr[0].SpentTime / 60).toFixed(2)
              );
            this.normalWorkArr[this.activeSortIndex].NextProgress =
              arr[0].NextProgres;
            // this.normalWorkArr[this.activeSortIndex].JobVariables =
            //   arr[0].JobVaruable;

            this.normalWorkArr[this.activeSortIndex].IsCirculation =
              arr[0].IsCirculation;
          }
          if (this.activeSort == 1) {
            //加班简报
            let bool = false;
            this.overWorkArr.forEach((item) => {
              if (item.JId == arr[0].JId) {
                this.$message({
                  type: "warning",
                  message: "加班任务中已存在此任务，可直接修改该任务耗时",
                });
                bool = true;
                return;
              }
            });
            if (bool) {
              return;
            }
            this.overWorkArr[this.activeSortIndex].Eve = {
              EId: arr[0].EventeID,
              EName: arr[0].EventeName,
              EFir: arr[0].EventeFir,
              EShape: 1,
            };
            this.overWorkArr[this.activeSortIndex].JId = arr[0].JId;
            this.overWorkArr[this.activeSortIndex].JProgress =
              arr[0].PreProgres + "%";
            this.overWorkArr[this.activeSortIndex].Progress = arr[0].PreProgres;
            this.overWorkArr[this.activeSortIndex].JEstimatedTime =
              arr[0].EstimatedTime;
            this.overWorkArr[this.activeSortIndex].JFir = arr[0].Fir;
            this.overWorkArr[this.activeSortIndex].JName = arr[0].Name;
            this.overWorkArr[this.activeSortIndex].JTakeTime =
              this.formateInputDecimal(
                parseFloat(arr[0].SpentTime / 60).toFixed(2)
              );
            this.overWorkArr[this.activeSortIndex].NextProgress =
              arr[0].NextProgres;
            // this.overWorkArr[this.activeSortIndex].JobVariables =
            //   arr[0].JobVaruable;

            this.overWorkArr[this.activeSortIndex].IsCirculation =
              arr[0].IsCirculation;
          }
        } else {
          this.getFatherList(arr);
        }
      }
    },
    selectProject(type, index) {
      this.$modal.show("chooseProject");
      if (type == 0) {
        //正常工作
        this.activeProSort = 0;
        this.activeProSortIndex = index;
      }
      if (type == 1) {
        //加班工作
        this.activeProSort = 1;
        this.activeProSortIndex = index;
      }
    },
    addOneMoreOverWork() {
      //增加一条加班记录
      this.overWorkArr.push({
        Eve: null,
        JId: 0,
        JProgress: "0%",
        rule: null,
        location: "公司",
        locationOther: null,
        JSpentTime: 0,
        JFir: null,
        JContent: null,
        taskName: null,
        inputWasteTime: null,
        OverTime: 0,
        JEstimatedTime: 0,
        JTakeTime: 0,
        JShape: 1,
        IncreaseProgress: 0,
        JTimeProgress: 0,
        JName: null,
        edit: true,
        editJob: true,
        Progress: 0,
        IsOverWork: false,
        OverTime: 0,
        IsWorkArea: false,
        IsTempTask: false,
        PntTaskId: "",
        PntTaskName: "",
        visible: false,
        editJob: true,
        isApply: false,
        BwId: null,
        NextProgress: 100,
        fileList: [],
      });
      this.$nextTick(() => {
        this.$refs.overwork[this.overWorkArr.length - 1].scrollIntoView({
          behavior: "smooth",
        });
      });
    },
    removeOverWork(index) {
      //删除一条加班记录
      this.$confirm("是否删除该条加班记录?", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.overWorkArr.splice(index, 1);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getDetail() {
      //获得简报详情....
      this.loading = true;
      this.loadData.prompt = null;
      this.$http
        .post("/Teams/Report/reportLoad.ashx", {
          usId: this.id,
          date: this.day,
          teamId: this.teamId,
        })
        .then((res) => {
          if (res.res == 0) {
            this.todayActivelist = res.data.active;
            this.loadData = res.data;
            this.loadData.highWords = res.data.HighFrequencyWords;
            this.userName = res.data.user.usName;
            this.frontMan = res.data.user;
            this.overTime_input = this.loadData.inputOverTime;
            this.selectOverTime = this.overTime_input
              ? this.overTime_input / 60
              : null;
            this.loadData.prompt = res.data.prompt;
            this.recordOverwork = res.data.isOverwork;
            this.rcoRatio = res.data.rcoRatio;
            if (res.data.autoDistri) {
              this.autoDistri = res.data.autoDistri;
            } else {
              this.autoDistri = false;
            }
            this.reportMan = [];
            this.reportMan.push({
              Name: res.data.user.usName,
              UsId: res.data.user.usId,
              Picture: res.data.user.usHead,
            });
            //无工作安排通知的人
            if (res.data.noworkNoticeLeader) {
              this.isNowork = true;
              const leader = res.data.noworkNoticeLeader;
              this.noticeUsers.push({
                UsId: leader.usId,
                Name: leader.usName,
                Picture: leader.usPicture,
              });
            }
            //责任审批人
            if (res.data.approverUser) {
              let item = res.data.approverUser;
              this.approverUser.push({
                UsId: item.usId,
                Name: item.usName,
                Picture: item.usPicture,
              });
            }
            //实际审批人
            if (res.data.actualApproverUser) {
              let item = res.data.actualApproverUser;
              this.actualApproverUser.push({
                UsId: item.usId,
                Name: item.usName,
                Picture: item.usPicture,
              });
            }
            //可见人
            this.visiblesUsers = res.data.visiblesUsers;
            if (this.visiblesUsers == null) {
              this.visiblesUsers = [];
            }
            let arr = [];
            this.visiblesUsers.forEach((item) => {
              arr.push({
                UsId: item.usId,
                Name: item.usName,
                Picture: item.usPicture,
              });
            });
            this.visiblesUsers = arr;
            if (this.loadData.bu !== null) {
              this.bid = res.data.bu.Bl.BId;
              // this.getCommentList();
              if (this.$route.query.open && this.bid) {
                this.$nextTick(() => {
                  this.$refs.commentView.scrollIntoView();
                });
              }
              //反馈
              // if (this.loadData.bu.CsTbs.length !== 0) {
              //   this.feedArr = [];
              //   this.loadData.bu.CsTbs.forEach((item) => {
              //     this.feedArr.push({
              //       Link: item.Link,
              //       Description: item.Description,
              //     });
              //   });
              // } else {
              //   this.feedArr = [
              //     {
              //       Link: "",
              //       Description: "",
              //     },
              //   ];
              // }
              this.inputReasons = "";
              this.tomorrowPlan = this.loadData.bu.Bl.NextContent;
              this.todayDiff = this.loadData.bu.Bl.NeedHelp;
            }
            // 结合模式1 2 3
            // let reotime = 0
            if (this.loadData.jobs) {
              this.loadData.jobs.forEach((item) => {
                //是加班任务并来自工作区
                let imgs = [];
                item.JobImgs.forEach((img, index) => {
                  imgs.push({
                    name: index + ".jpeg",
                    url: this.$url + img,
                    link: img,
                  });
                });
                // let preprogress = item.JProgress - item.IncreaseProgress
                if (item.IsOverWork) {
                  // item.JobVariables = item.JobVariables.map((j) => {
                  //   return {
                  //     ...j,
                  //     JobVariableSumCopy: j.JobVariableSum,
                  //   };
                  // });
                  this.overWorkArr.push({
                    rule: item.Rule,
                    location:
                      item.Location != "公司" && item.Location != "家"
                        ? "其他"
                        : item.Location,
                    locationOther:
                      item.Location != "公司" && item.Location != "家"
                        ? item.Location
                        : null,
                    Eve: item.Eve,
                    JId: item.JId,
                    JProgress: item.Progress + "%", //当前进度
                    newProgess: item.JProgress, //最新进度
                    JSpentTime: item.JSpentTime,
                    JFir: item.JFir,
                    JContent: item.JContent,
                    inputWasteTime: this.formateInputDecimal(
                      parseFloat(item.JSpentTime).toFixed(2)
                    ),
                    OverTime: item.OverTime,
                    JEstimatedTime: item.JEstimatedTime,
                    JTakeTime: item.JTakeTime,
                    JShape: item.JShape,
                    IncreaseProgress: item.IncreaseProgress,
                    JTimeProgress: item.JTimeProgress,
                    JName: item.JName,
                    Progress: item.LastProgress, //上次进度
                    IsOverWork: item.IsOverWork,
                    IsWorkArea: item.IsWorkArea,
                    OverTime: item.OverTime,
                    IsTempTask: item.IsTempTask,
                    PntTaskId: item.PntTaskId,
                    visible: false,
                    isApply: item.IsApply,
                    BwId: item.BwId,
                    NextProgress: item.NextProgress, //下次进度
                    fileList: imgs,
                    currentProgress: item.Progress, //当前进度
                    // JobVariables:
                    //   item.JobVariables && item.JobVariables.length
                    //     ? item.JobVariables.filter((s) => s.Shape == 1)
                    //     : [], //任务变量
                    OKR: item.OKR, //任务绩效
                    JobTrack: item.JobTrack, //任务运动轨迹
                    IsCirculation: item.IsCirculation,
                  });
                }
                if (!item.IsOverWork) {
                  // item.JobVariables = item.JobVariables.map((j) => {
                  //   return {
                  //     ...j,
                  //     JobVariableSumCopy: j.JobVariableSum,
                  //   };
                  // });
                  this.normalWorkArr.push({
                    Eve: item.Eve,
                    JId: item.JId,
                    JProgress: item.Progress + "%",
                    newProgess: item.JProgress, //最新进度
                    JSpentTime: item.JSpentTime,
                    JFir: item.JFir,
                    JContent: item.JContent,
                    inputWasteTime: this.formateInputDecimal(
                      parseFloat(item.JSpentTime).toFixed(2)
                    ),
                    OverTime: item.OverTime,
                    JEstimatedTime: item.JEstimatedTime,
                    JTakeTime: item.JTakeTime,
                    JShape: item.JShape,
                    IncreaseProgress: item.IncreaseProgress,
                    JTimeProgress: item.JTimeProgress,
                    JName: item.JName,
                    Progress: item.LastProgress,
                    IsOverWork: item.IsOverWork,
                    IsWorkArea: item.IsWorkArea,
                    OverTime: item.OverTime,
                    IsTempTask: item.IsTempTask,
                    PntTaskId: item.PntTaskId,
                    visible: false,
                    isApply: item.IsApply,
                    BwId: item.BwId,
                    NextProgress: item.NextProgress,
                    fileList: imgs,
                    currentProgress: item.Progress, //当前进度
                    // JobVariables:
                    //   item.JobVariables && item.JobVariables.length
                    //     ? item.JobVariables.filter((s) => s.Shape == 1)
                    //     : [], //任务变量
                    OKR: item.OKR, //任务绩效
                    JobTrack: item.JobTrack, //任务运动轨迹
                    IsCirculation: item.IsCirculation,
                  });
                }
              });
              this.overWorkArr.forEach((item) => {
                if (item.isApply) {
                  this.overWorkTime += item.inputWasteTime;
                }
              });
              this.normalWorkArr.forEach((item) => {
                // 来自工作区并且有项目
                //edit 该条任务是否能编辑   //editJob 是否能修改任务中的任务名
                if (item.Eve && item.IsWorkArea) {
                  item.edit = false;
                  item.editJob = false;
                }
                //来自工作区并且没有项目
                else if (item.IsWorkArea && !item.Eve) {
                  item.editJob = true;
                  item.edit = false;
                } else {
                  item.edit = true;
                  item.editJob = true;
                }
              });
              this.overWorkArr.forEach((item) => {
                // 来自工作区并且有项目
                if (item.Eve && item.IsWorkArea) {
                  item.edit = false;
                  item.editJob = false;
                } else if (item.IsWorkArea && !item.Eve) {
                  item.editJob = true;
                  item.edit = false;
                } else {
                  item.edit = true;
                  item.editJob = true;
                }
              });
              this.copyNormalWorkArr = JSON.stringify(this.normalWorkArr);
              JSON.parse(this.copyNormalWorkArr).forEach((item) => {
                this.workTimeInit += item.inputWasteTime * 1;
              });

              this.copyOverWorkArr = JSON.stringify(this.overWorkArr);
            }

            if (
              !this.loadData.IsFirstEdit &&
              (this.loadData.WorkBws.length !== 0 ||
                this.loadData.WorkBws.length !== 0)
            ) {
              setTimeout(() => {
                this.workChart();
              }, 500);
            }
            if (
              !this.loadData.IsFirstEdit &&
              (this.loadData.OverBws.length !== 0 ||
                this.loadData.OverBws.length !== 0)
            ) {
              setTimeout(() => {
                this.overworkChart();
              }, 500);
            }
            // this.getWorkTimeAixs();
            this.getReportWorkStatus();
            setTimeout(() => {
              this.loading = false;
              // this.getPlanJob();
              // this.getDetailJob();
              this.getApproveProcess();
              // this.getDetailCustomer();
              this.formateProgress();
              if (this.loadData.prompt) {
                this.$modal.show("promptBox");
              }
            }, 500);
          } else {
            this.loading = false;
            this.loadData = {};
            this.tipsTitle = res.msg;
            this.userName = res.data.user.usName;
          }
        });
    },
    /**
     * 简报工作状态显示
     */
    getReportWorkStatus() {
      this.workStatusLoading = true;
      const data = {
        teamId: this.teamId,
        usId: this.id,
        date: this.day,
      };
      this.$http
        .post("/Teams/Report/reportWorkStatus.ashx", data)
        .then((resp) => {
          if (resp.res == 0) {
            this.loadData.WorkBws = resp.data.WorkBws;
            this.loadData.OverBws = resp.data.OverBws;
            this.loadData.timeAxis = resp.data.timeAxis;
            if (
              !this.loadData.IsFirstEdit &&
              (this.loadData.WorkBws.length !== 0 ||
                this.loadData.WorkBws.length !== 0)
            ) {
              setTimeout(() => {
                this.workChart();
              }, 500);
            }
            if (
              !this.loadData.IsFirstEdit &&
              (this.loadData.OverBws.length !== 0 ||
                this.loadData.OverBws.length !== 0)
            ) {
              setTimeout(() => {
                this.overworkChart();
              }, 500);
            }
          }
        })
        .finally(() => (this.workStatusLoading = false));
    },

    formateInputDecimal(num) {
      if (!num) {
        return 0;
      }
      let reg = /00/;
      if (reg.test(num)) {
        let i = num.indexOf(".");
        num = num.slice(0, i);
      }
      if (num.length > 1 && num[num.length - 1] == 0) {
        num = num.slice(0, num.length - 1);
      }
      return parseFloat(num);
    },

    getDetailJob() {
      //今日已完成
      this.$http
        .get("/Teams/Report/reportJobs.ashx", {
          params: {
            usId: this.id,
            date: this.day,
            type: 0, //已完成的任务
          },
        })
        .then((res) => {
          if (res.res == 0) {
            this.todayComplete = res.data.jobs;
            this.$http
              .get("/Teams/Report/reportJobs.ashx", {
                params: {
                  usId: this.id,
                  date: this.day,
                  type: 1, //审批中任务
                },
              })
              .then((res) => {
                this.approvalArr = res.data.jobs;
                // 今日完成和审批都有
                if (this.approvalArr.length && this.todayComplete.length) {
                  this.todayIndex = 0;
                  this.todayList = this.todayComplete;
                  //只有审批
                } else if (
                  this.approvalArr.length != 0 &&
                  this.todayComplete.length == 0
                ) {
                  this.todayIndex = 1;
                  this.todayList = this.approvalArr;
                  //只有完成
                } else if (
                  this.approvalArr.length == 0 &&
                  this.todayComplete.length != 0
                ) {
                  this.todayIndex = 0;
                  this.todayList = this.todayComplete;
                }
              });
          }
        });
    },
    getDetailCustomer() {
      //客户相关
      this.$http
        .get("/Teams/Report/reportCustomer.ashx", {
          params: {
            UsId: this.id,
            Time: this.day,
          },
        })
        .then((res) => {
          if (res.res == 0) {
            this.addCustomerList = res.data.CAddList;
            this.dealCustomerList = res.data.CSignList;
            this.CRecordList = res.data.CRecordList;
          }
        });
    },
    getPlanJob() {
      //计划列表
      this.todayPlanList = [];
      this.$http
        .get("/Teams/Report/reportPlan.ashx", {
          params: {
            UsId: this.id,
            Time: this.day,
          },
        })
        .then((res) => {
          if (res.res == 0) {
            res.data.forEach((item) => {
              let percent = "";
              if (item.Target == 0) {
                percent = 0;
              } else {
                percent = parseFloat(
                  ((item.Complete / item.Target) * 100).toFixed(2)
                );
              }
              this.todayPlanList.push({
                Complete: item.Complete,
                Target: item.Target,
                inputValue: "",
                JobName: item.JobName,
                per: percent,
              });
            });
          }
        });
    },
    addReport() {
      //提交简报
      this.normal = []; //正常工作
      for (let i = 0; i < this.normalWorkArr.length; i++) {
        let item = this.normalWorkArr[i];
        // if (!item.Eve) {
        //   this.$notify({
        //     type: "error",
        //     message: "本日任务中第" + (i + 1) + "条任务的所属为空",
        //   });
        //   return;
        // }
        let percent = item.JProgress.slice(0, item.JProgress.length - 1);

        this.normal.push({
          // projectId: item.Eve.EId,
          taskId: item.JId,
          content: item.JContent,
          taskName: item.taskName,
          inputWasteTime: item.inputWasteTime,
          progressValue: percent,
          PntTaskId: item.PntTaskId,
          isApply: item.isApply,
          isTempTask: item.IsTempTask,
          BwId: item.BwId,
          Imgs: item.fileList.map((img) => {
            return img.link;
          }),
          // jvListStr:
          //   item.JobVariables && item.JobVariables.length
          //     ? JSON.stringify(
          //         item.JobVariables.map((m) => {
          //           return {
          //             Id: 0,
          //             JVId: m.Id,
          //             JId: m.JId,
          //             Value: item.IsCirculation ? null : m.Default,
          //           };
          //         })
          //       )
          //     : "[]",
        });
      }
      // this.$refs.normalFile.forEach((item,index)=>{
      // 	item.submit()
      // 	// let img=[]
      //  //     console.log(item)
      // 	// item.fileList.forEach(val=>{
      // 	// 	if(val.status=='success'){
      // 	// 		img.push(val.response.link)
      // 	// 	}
      // 	// })
      // 	// this.normal[index].Imgs=img
      // })
      this.overWork = [];
      if (this.recordOverwork) {
        for (let i = 0; i < this.overWorkArr.length; i++) {
          let item = this.overWorkArr[i];
          // if (!item.Eve) {
          //   this.$notify({
          //     type: "error",
          //     message: "加班任务中第" + (i + 1) + "条任务的所属为空",
          //   });
          //   return;
          // }
          let percent = item.JProgress.slice(0, item.JProgress.length - 1);
          this.overWork.push({
            // projectId: item.Eve.EId,
            taskId: item.JId,
            content: item.JContent,
            taskName: item.taskName,
            inputWasteTime: item.inputWasteTime,
            progressValue: percent,
            rule: item.rule,
            location:
              item.location == "其他" ? item.locationOther : item.location,
            PntTaskId: item.PntTaskId,
            isApply: item.isApply,
            isTempTask: item.IsTempTask,
            BwId: item.BwId,
            Imgs: item.fileList.map((img) => {
              return img.link;
            }),
            // jvListStr:
            //   item.JobVariables && item.JobVariables.length
            //     ? JSON.stringify(
            //         item.JobVariables.map((m) => {
            //           return {
            //             Id: 0,
            //             JVId: m.Id,
            //             JId: m.JId,
            //             Value: item.IsCirculation ? null : m.Default,
            //           };
            //         })
            //       )
            //     : "[]",
          });
        }
      }

      if (!this.feedArr[0]) {
        this.feedArr = [];
      }
      if (!this.approverUser[0]) {
        this.$notify({
          type: "error",
          message: "请选择审批人",
        });
        return;
      }
      if (!this.visiblesUsers[0]) {
        this.$notify({
          type: "error",
          message: "请选择可见人",
        });
        return;
      }
      //正常工作
      let codeBool = false;
      let errmsg = "";
      //正常工作的非空验证
      for (let i = 0; i < this.normal.length; i++) {
        for (let key in this.normal[i]) {
          // console.log(normal)
          // if (this.normal[i]['taskId'] !== 0 && (!this.normal[i]['content'])) {
          // 	codeBool = true
          // 	errmsg = '本日任务中第' + (i + 1) + '条任务的工作详情为空'
          // 	break
          // }
          if (
            this.normal[i]["inputWasteTime"] == "" &&
            this.normal[i]["inputWasteTime"] !== 0
          ) {
            codeBool = true;
            errmsg = "本日任务中第" + (i + 1) + "条任务的大概耗时为空";
            break;
          }
          // if (this.normal[i]["progressValue"] == "") {
          //   codeBool = true;
          //   errmsg = "本日任务中第" + (i + 1) + "条任务的工作进度为空";
          //   break;
          // }
        }
        if (codeBool) {
          break;
        }
      }
      this.normal.forEach((item) => {
        if (item.taskId == 0) {
          item.taskId = null;
        }
      });
      this.overWork.forEach((item) => {
        if (item.taskId == 0) {
          item.taskId = null;
        }
      });
      if (codeBool) {
        this.$notify({
          type: "error",
          message: errmsg,
        });
        return;
      }
      //加班工作的非空验证
      if (this.recordOverwork) {
        for (let i = 0; i < this.overWork.length; i++) {
          for (let key in this.overWork[i]) {
            // if (this.overWork[i]['taskId'] !== 0 && (!this.overWork[i]['content']) && this.overWork[i]['isApply']) {
            // 	codeBool = true
            // 	errmsg = '本日加班任务中第' + (i + 1) + '条任务的工作详情为空'
            // 	break
            // }
            if (this.overWork[i]["inputWasteTime"] == "") {
              codeBool = true;
              errmsg = "本日加班任务中第" + (i + 1) + "条任务的大概耗时为空";
              break;
            }
            // if (this.overWork[i]["progressValue"] == "") {
            //   codeBool = true;
            //   errmsg = "本日加班任务中第" + (i + 1) + "条任务的工作进度为空";
            //   break;
            // }
          }
          if (codeBool) {
            break;
          }
        }
        if (codeBool) {
          this.$notify({
            type: "error",
            message: errmsg,
          });
          return;
        }
      }
      //可见人
      this.visibleArr = [];
      if (this.visiblesUsers.length !== 0 && this.visiblesUsers !== null) {
        this.visibleArr = [];
        this.visiblesUsers.forEach((item) => {
          this.visibleArr.push(item.UsId);
        });
      }

      if (!codeBool) {
        this.reportSubmit();
      }
    },
    reportSubmit() {
      this.$modal.hide("auditReason");
      this.loading = true;
      if (!this.recordOverwork) {
        this.overWork = [];
        this.overTime_input = 0;
      }
      this.$http
        .post("/Teams/Report/reportAdd.ashx", {
          normalWork: this.normal,
          overWork: this.overWork,
          date: this.day,
          approver: this.approverUser[0].UsId,
          visibles: this.visibleArr,
          nextContent: this.tomorrowPlan,
          needHelp: this.todayDiff,
          feedArr: this.feedArr,
          auditReason: this.inputReasons,
          overTimeInput: this.overTime_input ? this.overTime_input : 0,
          isOverwork: this.recordOverwork,
          autoDistri: this.autoDistri,
          noworkNotice: this.noticeUsers.length ? this.noticeUsers[0].UsId : 0,
          teamId: this.teamId,
        })
        .then((res) => {
          if (res.res == 0) {
            this.$notify({
              type: "success",
              message: "提交简报成功",
            });
            if (res.data == 2) {
              //自动审核通过
              this.reportDetail.isSuccess = true;
            }
            this.reportDetail.time = this.overTime_input;
            this.reportDetail.isOverwork = this.recordOverwork;
            this.reportDetail.endTime = this.loadData.downt.slice(
              0,
              this.loadData.downt.length - 3
            );
            this.$emit("sendReport", this.reportDetail);
            this.closeReport(true);
            this.$modal.hide("report");
          }
          if (res.res == 2) {
            this.loadData.mgs = res.data;
            this.$modal.show("auditReason");
            setTimeout(() => {
              this.$refs.textArea.focus();
            }, 1000);
            this.auto = true;
          }
          if (res.res == 5) {
            this.tipsBox.data = res.data;
            this.tipsBox.msg = res.msg;
            this.$modal.show("tipsBox");
          }
        })
        .finally(() => (this.loading = false));
    },
    reportResubmit() {
      this.inputReasons = this.inputReasons.replace(/^\s+|\s+$/g, "");
      if (this.inputReasons.length < 10) {
        this.$message({
          type: "warning",
          message: "申请原因不能少于10个字",
        });
        return;
      }
      this.addReport();
    },
    workChart() {
      //工作占比
      let option = {};
      option = {
        tooltip: {
          trigger: "item",
          formatter: function (params) {
            if (params) {
              let str = "";
              str +=
                '<div style="with:200px;box-shadow:0px 5px 10px -2px rgba(74, 144, 226, 0.9);padding:1rem;font-size:14px;border-radius:4px">';
              params.data.name =
                params.data.name.length > 6
                  ? params.data.name.slice(0, 15)
                  : params.data.name;
              return (
                str +
                params.seriesName +
                ":" +
                params.data.name +
                "<br/> 耗时:" +
                params.data.value +
                "h </div>"
              );
            }
          },
          padding: 0,
          backgroundColor: "rgba(0,0,0,.3)",
          borderRadius: 4,
        },
        series: [
          {
            name: "任务名",
            type: "pie",
            radius: ["40%", "65%"],
            label: {
              normal: {
                show: true,
                formatter: function (params) {
                  if (params.data.name) {
                    params.data.name =
                      params.data.name.length > 8
                        ? params.data.name.slice(0, 7) + "..."
                        : params.data.name;
                  }
                  return params.data.name;
                },
                backgroundColor: "#FFF",
                // borderColor: '#aaa',
                // borderWidth: 1,
                padding: 4,
                borderRadius: 4,
              },
            },
            labelLine: {
              normal: {
                show: true,
              },
            },
            itemStyle: {
              normal: {
                color: function (params) {
                  //自定义颜色
                  // console.log(params)
                  var colorList = [
                    "#63b2ee",
                    "#76da91",
                    "#f8cb7f",
                    "#f89588",
                    "#7cd6cf",
                    "#9192ab",
                    "#7898e1",
                    "#efa666",
                    "#eddd86",
                    "#9987ce",
                  ];
                  return colorList[params.dataIndex];
                },
              },
            },
            data: this.loadData.WorkBws,
          },
        ],
      };
      this.$nextTick(() => {
        if (!document.getElementById("workChart")) {
          return;
        }
        let workChart = this.$echarts.init(
          document.getElementById("workChart")
        );
        this.workChart_ = workChart;
        workChart.setOption(option);
      });
    },
    overworkChart() {
      //加班占比
      let option = {};
      option = {
        tooltip: {
          trigger: "item",
          formatter: function (params) {
            if (params) {
              let str = "";
              str +=
                '<div style="with:200px;box-shadow:0px 5px 10px -2px rgba(74, 144, 226, 0.9);padding:1rem;font-size:14px;border-radius:4px">';
              params.data.name =
                params.data.name.length > 6
                  ? params.data.name.slice(0, 15)
                  : params.data.name;
              return (
                str +
                params.seriesName +
                ":" +
                params.data.name +
                "<br/> 耗时:" +
                params.data.value +
                "h </div>"
              );
            }
          },
          padding: 0,
          backgroundColor: "rgba(0,0,0,.3)",
          borderRadius: 4,
        },
        series: [
          {
            name: "任务名",
            type: "pie",
            radius: ["40%", "65%"],
            label: {
              normal: {
                show: true,
                formatter: function (params) {
                  if (params.data.name) {
                    params.data.name =
                      params.data.name.length > 8
                        ? params.data.name.slice(0, 7) + "..."
                        : params.data.name;
                  }
                  return params.data.name;
                },
                backgroundColor: "#FFF",
                padding: 4,
                borderRadius: 4,
              },
            },
            labelLine: {
              normal: {
                show: true,
              },
            },
            itemStyle: {
              normal: {
                color: function (params) {
                  //自定义颜色
                  // console.log(params)
                  var colorList = [
                    "#63b2ee",
                    "#76da91",
                    "#f8cb7f",
                    "#f89588",
                    "#7cd6cf",
                    "#9192ab",
                    "#7898e1",
                    "#efa666",
                    "#eddd86",
                    "#9987ce",
                  ];
                  return colorList[params.dataIndex];
                },
              },
            },
            data: this.loadData.OverBws,
          },
        ],
      };
      this.$nextTick(() => {
        if (!document.getElementById("overworkChart")) {
          return;
        }
        let workChart = this.$echarts.init(
          document.getElementById("overworkChart")
        );
        this.overWorkChart_ = workChart;
        workChart.setOption(option);
      });
    },
    approveReport() {
      //审批简报
      this.$modal.show("correctTime");
    },
    onConfirm() {
      //审批接口 审批通过
      if (this.overWorkArr.length > 0 && !this.isApplyOverTime) {
        this.$message({
          showClose: true,
          message: "请先认真审核所有加班任务",
          type: "warning",
        });
        return;
      }
      this.$refs.correctTime.loadBtn(true);
      let obj = {};
      if (this.appWay == 3) {
        //d
        obj = {
          teamId: this.teamId,
          BId: this.loadData.bu.Bl.Id, //简报id
          ot: this.appWay, //填写模式
          reotime: this.changeTime1, //修改加班的时间
          reasontxt: this.reasons, //修改时间的原因
          Res: true,
        };
      } else {
        //a,b,c
        obj = {
          teamId: this.teamId,
          BId: this.loadData.bu.Bl.Id,
          ot: this.appWay,
          Res: true,
        };
      }
      this.$http.post("/Teams/Report/reportApprove.ashx", obj).then((res) => {
        this.$refs.correctTime.loadBtn(false);
        if (res.res == 0) {
          this.$notify({
            type: "success",
            message: "审核简报成功",
          });
          this.$emit("successHandle");
          this.$emit("applyReport");
          this.reportDetail.isApprove = true; //已审批
          if (this.appWay == 3) {
            //修改加班时间
            this.reportDetail.time = this.changeTime1 * 60;
          } else if (this.appWay == 4) {
            this.reportDetail.time =
              this.loadData.autoOverTimeAppliedOnlyUrgent;
          } else if (this.appWay == 2) {
            //填报时间
            this.reportDetail.time = this.overTime_input;
          } else if (this.appWay == 1) {
            //已推荐时间
            this.reportDetail.time = this.loadData.autoOverTimeApplied;
          }
          this.reportDetail.isOverwork = this.recordOverwork;
          this.reportDetail.endTime = this.loadData.downt.slice(
            0,
            this.loadData.downt.length - 3
          );
          this.$modal.hide("correctTime");
          setTimeout(() => {
            this.$emit("sendReport", this.reportDetail);
            this.closeReport(false);
            this.$modal.hide("report");
          }, 500);
        }
      });
    },
    rejectReport() {
      //驳回简报
      if (!this.rejectData.reasontxt) {
        this.$notify({
          message: "请输入驳回原因",
          type: "warning",
        });
        return;
      }
      this.$refs.rejectReport.loadBtn(true);
      this.$http
        .post("/Teams/Report/reportApprove.ashx", {
          teamId: this.teamId,
          BId: this.loadData.bu.Bl.Id,
          Res: false,
          reasontxt: this.rejectData.reasontxt,
          resubmit: this.rejectData.resubmit,
          kid: this.KpiData.KId,
          score: this.KpiData.Score,
          complaint: this.KpiData.IsComplaint,
        })
        .then((res) => {
          console.log(res);
          if (res.res == 0) {
            this.$notify({
              type: "success",
              message: "已驳回该简报",
            });
            // this.reportDetail.isReject = true
            this.$refs.rejectReport.loadBtn(false);
            this.$modal.hide("rejectReport");
            setTimeout(() => {
              this.$emit("sendReport", this.reportDetail);
              this.closeReport(false);
              this.$modal.hide("report");
            }, 500);
          }
        });
    },
    getKpi(formdata) {
      this.KpiData = formdata;
    },
    addFeed() {
      //增加一条反馈
      this.feedArr.push({
        Link: "",
        Description: "",
      });
    },
    removeFeed(index) {
      //删除一条反馈
      this.feedArr.splice(index, 1);
      this.$refs.feed.forEach((item) => {
        item.style.transform = "translateX(0px)";
      });
      this.$refs.feedRemove.forEach((item) => {
        item.style.right = "-9999px";
      });
    },
    confirmApproverUser(arr) {
      let obj = {
        Name: arr[0].Name,
        UsId: arr[0].UsId,
        Picture: arr[0].Picture,
      };
      this.approverUser.splice(0, 1, obj);
    },
    confirmVsiblesUsers(arr) {
      this.visiblesUsers = arr;
    },
    confirmNoticeUsers(arr) {
      this.noticeUsers = arr;
    },
    addTime(time, index) {
      //正常工作时间统计
      let sumA = 0;
      if (isNaN(time)) {
        this.normalWorkArr[index].inputWasteTime = 0;
      }
      this.normalWorkArr.forEach((item) => {
        let a = item.inputWasteTime * 1;
        sumA += a;
      });
      this.workTime = sumA;
    },

    addOverTime(time, index) {
      //加班时间统计
      if (!this.overWorkArr[index]) {
        return;
      }
      let sumB = 0;
      if (isNaN(time)) {
        this.overWorkArr[index].inputWasteTime = 0;
      }
      this.overWorkArr.forEach((item) => {
        if (item.isApply) {
          sumB += item.inputWasteTime * 1;
        }
      });
      this.overWorkTime = sumB;
      // console.log(this.overWorkTime)
    },
    formateProgress() {
      let i = 100;
      while (i >= 0) {
        this.progressOptions.push({
          value: i + "%",
        });
        i -= 5;
      }
    },
    // showTips() {
    // 	this.showExtraTips = true
    // },
    // closeTips() {
    // 	this.showExtraTips = false
    // },
    touchStart(ev, index) {
      return;
      ev = ev || event;
      //tounches类数组，等于1时表示此时有只有一只手指在触摸屏幕
      this.disX = 0;
      if (this.$refs.normal) {
        this.$refs.normal.forEach((item) => {
          item.style.transform = "translateX(0px)";
        });

        if (this.$refs.remove) {
          this.$refs.remove.forEach((item) => {
            item.style.right = "-9999px";
          });
        }
        if (this.$refs.status) {
          this.$refs.status.forEach((item) => {
            item.style.right = "-9999px";
          });
        }
      }
      if (this.$refs.overwork) {
        this.$refs.overwork.forEach((item) => {
          item.style.transform = "translateX(0px)";
        });
        if (this.$refs.overRemove) {
          this.$refs.overRemove.forEach((item) => {
            item.style.right = "-9999px";
          });
        }
        if (this.$refs.overStatus) {
          this.$refs.overStatus.forEach((item) => {
            item.style.right = "-9999px";
          });
        }
      }
      if (this.$refs.approveWork) {
        this.$refs.approveWork.forEach((item) => {
          item.style.transform = "translateX(0px)";
        });
        this.$refs.approveWorkStatus.forEach((item) => {
          item.style.right = "-9999px";
        });
      }
      if (this.$refs.approveOverwork) {
        this.$refs.approveOverwork.forEach((item) => {
          item.style.transform = "translateX(0px)";
        });
        this.$refs.approveOverworkStatus.forEach((item) => {
          item.style.right = "-9999px";
        });
      }
      if (this.$refs.feed) {
        this.$refs.feed.forEach((item) => {
          item.style.transform = "translateX(0px)";
        });
        this.$refs.feedRemove.forEach((item) => {
          item.style.right = "-9999px";
        });
      }

      if (ev.touches.length == 1) {
        // 记录开始位置
        this.startX = ev.touches[0].clientX;
      }
    },
    touchMove(ev) {
      return;
      ev = ev || event;
      //获取删除按钮的宽度，此宽度为滑块左滑的最大距离
      let wd = 100;
      if (ev.touches.length == 1) {
        // 滑动时距离浏览器左侧实时距离
        this.moveX = ev.touches[0].clientX;

        //起始位置减去 实时的滑动的距离，得到手指实时偏移距离
        this.disX = this.startX - this.moveX;
        // console.log(this.disX)
        // 如果是向右滑动或者不滑动，不改变滑块的位置
        if (this.disX < 0 || this.disX == 0) {
          this.deleteSlider = "transform:translateX(0px)";
          // 大于0，表示左滑了，此时滑块开始滑动
        } else if (this.disX > 0) {
          //具体滑动距离我取的是 手指偏移距离*5。
          this.deleteSlider = "transform:translateX(-" + this.disX * 5 + "px)";

          // 最大也只能等于删除按钮宽度
          if (this.disX * 5 >= wd) {
            this.deleteSlider = "transform:translateX(-" + wd + "px)";
          }
        }
      }
    },
    touchEnd(ev, index) {
      // console.log('touchend')
      ev = ev || event;
      this.activeIndex = index;
      // let wd = 12;
      let wd = this.$refs.status[this.activeIndex].offsetWidth;
      if (ev.changedTouches.length == 1) {
        let endX = ev.changedTouches[0].clientX;
        this.disX = this.startX - endX;
        // console.log(this.disX)
        //如果距离小于删除按钮一半,强行回到起点
        // console.log(wd)
        if (this.disX * 5 < wd / 2) {
          this.$refs.normal[this.activeIndex].style.transform =
            "translateX(0px)";
          if (this.$refs.status) {
            this.$refs.status[this.activeIndex].style.right = "-9999px";
          }
        } else {
          //大于一半 滑动到最大值
          this.$refs.normal[this.activeIndex].style.transform =
            "translateX(-300px)";
          this.$refs.status[this.activeIndex].style.right = "75px";
        }
      }
    },
    touchEndOver(ev, index) {
      ev = ev || event;
      this.activeOverIndex = index;
      // let wd = this.$refs.overStatus[this.activeIndex].offsetWidth;
      let wd = this.$refs.overStatus[this.activeIndex].offsetWidth;
      if (ev.changedTouches.length == 1) {
        let endX = ev.changedTouches[0].clientX;
        this.disX = this.startX - endX;
        // console.log(this.disX)
        //如果距离小于删除按钮一半,强行回到起点
        if (this.disX * 5 < wd / 2) {
          this.$refs.overwork[this.activeOverIndex].style.transform =
            "translateX(0px)";
          if (this.$refs.overStatus) {
            this.$refs.overStatus[this.activeOverIndex].style.right = "-9999px";
          }
        } else {
          //大于一半 滑动到最大值
          this.$refs.overwork[this.activeOverIndex].style.transform =
            "translateX(-300px)";
          this.$refs.overStatus[this.activeOverIndex].style.right = "75px";
        }
      }
    },
    touchEndApprove(ev, index) {
      ev = ev || event;
      let wd = this.$refs.approveWork[index].offsetWidth;

      if (ev.changedTouches.length == 1) {
        let endX = ev.changedTouches[0].clientX;

        this.disX = this.startX - endX;
        console.log(this.disX);
        if (this.disX * 5 < wd / 2 && this.disX > 10) {
          this.$refs.approveWork[index].style.transform = "translateX(0px)";
          if (this.$refs.approveWorkStatus) {
            this.$refs.approveWorkStatus[index].style.right = "-9999px";
          }
        } else if (this.disX * 5 >= wd / 2 && this.disX > 10) {
          //大于一半 滑动到最大值
          this.$refs.approveWork[index].style.transform = "translateX(-9999px)";
          this.$refs.approveWorkStatus[index].style.right = "0px";
        }
      }
    },
    touchEndOverApprove(ev, index) {
      ev = ev || event;
      let wd = this.$refs.approveWork[index].offsetWidth;
      if (ev.changedTouches.length == 1) {
        let endX = ev.changedTouches[0].clientX;
        this.disX = this.startX - endX;
        if (this.disX * 5 < wd / 2 && this.disX > 10) {
          this.$refs.approveOverwork[index].style.transform = "translateX(0px)";
          if (this.$refs.approveOverworkStatus) {
            this.$refs.approveOverworkStatus[index].style.right = "-9999px";
          }
        } else if (this.disX * 5 >= wd / 2 && this.disX > 10) {
          //大于一半 滑动到最大值
          this.$refs.approveOverwork[index].style.transform =
            "translateX(-9999px)";
          this.$refs.approveOverworkStatus[index].style.right = "0px";
        }
      }
    },
    touchFeedEnd(ev, index) {
      ev = ev || event;
      this.activeFeed = index;
      let wd = this.$refs.feed[this.activeFeed].offsetWidth;
      if (ev.changedTouches.length == 1) {
        let endX = ev.changedTouches[0].clientX;
        this.disX = this.startX - endX;
        // console.log(this.disX)
        //如果距离小于删除按钮一半,强行回到起点
        if (this.disX * 5 < wd / 2) {
          this.$refs.feed[this.activeFeed].style.transform = "translateX(0px)";
          this.$refs.feedRemove[this.activeFeed].style.right = "-9999px";
        } else {
          //大于一半 滑动到最大值
          this.$refs.feed[this.activeFeed].style.transform =
            "translateX(-52px)";
          this.$refs.feedRemove[this.activeFeed].style.right = "-1px";
        }
      }
    },
    compareNum(a, b, maxNum) {
      //百分数以下禁用
      if (b == undefined) {
        return false;
      }
      a = Number(a.slice(0, a.length - 1));
      b = Number(b);

      if (b <= a && a <= maxNum) {
        return false;
      } else {
        return true;
      }
    },
    turnLastDay() {
      this.reportDetail = {};
      Object.assign(this.$data, this.$options.data());
      if (this.obj.id && this.obj.day) {
        this.id = this.obj.id;
        this.day = this.obj.day;
        this.$modal.show("report");
      } else {
        this.id = this.$route.params.id;
        this.day = this.$route.params.date;
        this.$modal.show("report");
      }

      // this.day = new Date(new Date(this.day).getTime() - 24 * 60 * 60 * 1000).toLocaleString().split(" ")[0].replace(
      // 	/\//g, "-")
      const nd = new Date(this.day);
      this.day = new Date(nd.setDate(nd.getDate() - 1)).timeFormat(
        "yyyy-MM-dd"
      );

      this.$emit("getDay", this.day);
      this.getDetail();
      this.$modal.hide("taskM");
      this.referer = this.isMember
        ? "/report/memberReport"
        : "/report/myReport";
      this.referer += "/" + this.teamId + "/" + this.id + "/" + this.day;
      this.url = this.isMember ? "/report/memberReport" : "/report/myReport";
      this.url += "/" + this.teamId + "/" + this.id + "/" + this.day;

      if (this.isAppTag || this.$xStorage.getItem("isapp_tag")) {
        this.$router.replace(
          `/appReport/${this.teamId}/${this.id}/${this.day}`
        );
      }
    },
    turnNextDay() {
      if (
        new Date(this.day).getTime() + 24 * 60 * 60 * 1000 >
        new Date().getTime()
      ) {
        return;
      }
      this.reportDetail = {};
      Object.assign(this.$data, this.$options.data());
      if (this.obj.teamId && this.obj.id && this.obj.day) {
        this.teamId = this.obj.teamId;
        this.id = this.obj.id;
        this.day = this.obj.day;
        this.$modal.show("report");
      } else {
        this.teamId = this.$route.params.teamId;
        this.id = this.$route.params.id;
        this.day = this.$route.params.date;
        this.$modal.show("report");
      }

      // this.day = new Date(new Date(this.day).getTime() + 24 * 60 * 60 * 1000).toLocaleString().split(" ")[0].replace(
      // 	/\//g, "-")
      const nd = new Date(this.day);
      this.day = new Date(nd.setDate(nd.getDate() + 1)).timeFormat(
        "yyyy-MM-dd"
      );

      this.$emit("getDay", this.day);
      this.getDetail();
      this.$modal.hide("taskM");
      this.referer = this.isMember
        ? "/report/memberReport"
        : "/report/myReport";
      this.referer += "/" + this.teamId + "/" + this.id + "/" + this.day;
      this.url = this.isMember ? "/report/memberReport" : "/report/myReport";
      this.url += "/" + this.teamId + "/" + this.id + "/" + this.day;

      if (this.isAppTag || this.$xStorage.getItem("isapp_tag")) {
        this.$router.replace(
          `/appReport/${this.teamId}/${this.id}/${this.day}`
        );
      }
    },
    //获得时间轴
    getWorkTimeAixs() {
      this.$http
        .get("/Teams/Report/GetWorkStatusInHour.ashx", {
          params: {
            date: this.day,
            uid: this.id,
          },
        })
        .then((res) => {
          if (res.res == 0) {
            if (!res.data) {
              this.recordList = [];
              return;
            }
            this.recordList = JSON.parse(res.data);
            // console.log(this.recordList)
            if (this.recordList) {
              this.recordList.forEach((item) => {
                let maxThreeNum = [];
                maxThreeNum.push(item.CORTime);
                maxThreeNum.push(item.ETTime);
                maxThreeNum.push(item.FreeTime);
                maxThreeNum.push(item.NotExistTime);
                maxThreeNum.push(item.UnknownTime);
                maxThreeNum = maxThreeNum.sort((a, b) => {
                  return b - a;
                });
                item.maxThreeNum = maxThreeNum.slice(0, 3);
                let names = [];
                item.maxThreeNum.forEach((val) => {
                  if (val == 0) {
                    return;
                  }
                  if (item.CORTime == val) {
                    names.push("工作: " + (val / 60).toFixed(2) + "min");
                  }
                  if (item.ETTime == val) {
                    names.push("娱乐: " + (val / 60).toFixed(2) + "min");
                  }
                  if (item.FreeTime == val) {
                    names.push("空闲: " + (val / 60).toFixed(2) + "min");
                  }
                  if (item.UnknownTime == val) {
                    names.push("未知: " + (val / 60).toFixed(2) + "min");
                  }
                  if (item.NotExistTime == val) {
                    names.push("离线: " + (val / 60).toFixed(2) + "min");
                  }
                });
                item.names = names.slice(0, 3);
              });
            }
          }
        });
    },
    getList(arr) {
      if (this.activeProSort == 0) {
        this.normalWorkArr[this.activeProSortIndex].Eve = {
          EId: arr[0].proId,
          EName: arr[0].proName,
          EFir: arr[0].fir,
          EShape: 1,
        };
      }
      if (this.activeProSort == 1) {
        this.overWorkArr[this.activeProSortIndex].Eve = {
          EId: arr[0].proId,
          EName: arr[0].proName,
          EFir: arr[0].fir,
          EShape: 1,
        };
      }
    },
    selectFatherTask(type, index) {
      this.$modal.show("chooseTask");
      this.normalWorkArr.forEach((item) => {
        item.visible = false;
        // this.$set(this,item.visible,false)
      });
      this.overWorkArr.forEach((item) => {
        item.visible = false;
        // this.$set(this,item.visible,false)
      });
      this.activeTask = 1;
      if (type == 0) {
        (this.activeFatherSort = 0), (this.activeFatherSortIndex = index);
      }
      if (type == 1) {
        (this.activeFatherSort = 1), (this.activeFatherSortIndex = index);
      }
    },
    getFatherList(arr) {
      // debugger;
      if (arr.length) {
        if (this.activeFatherSort == 0) {
          this.normalWorkArr[this.activeFatherSortIndex].PntTaskId = arr[0].JId;
          this.normalWorkArr[this.activeFatherSortIndex].Eve = {
            EId: arr[0].EventeID,
            EName: arr[0].EventeName,
            EFir: arr[0].EventeFir,
            EShape: 1,
          };
        }
        if (this.activeFatherSort == 1) {
          this.overWorkArr[this.activeFatherSortIndex].PntTaskId = arr[0].JId;
          this.overWorkArr[this.activeFatherSortIndex].Eve = {
            EId: arr[0].EventeID,
            EName: arr[0].EventeName,
            EFir: arr[0].EventeFir,
            EShape: 1,
          };
        }
      }
    },
    taskUpdate(obj, bool) {
      if (bool) {
        this.normalWorkArr.forEach((item) => {
          if (obj.jobID == item.JId) {
            item.JEstimatedTime = obj.estimatedTime * 7.5;
          }
        });
        this.overWorkArr.forEach((item) => {
          if (item.jobID == item.JId) {
            item.JEstimatedTime = obj.estimatedTime * 7.5;
          }
        });
      }
    },
    checkeBox() {
      this.recommandOverTime = 0;
      this.overWorkTime = 0;
      this.overTimereadOnly = true;
      this.overWorkArr.forEach((item) => {
        if (item.isApply) {
          this.overWorkTime += item.inputWasteTime;
          this.recommandOverTime += item.OverTime;
          this.overTimereadOnly = false;
        }
      });
    },
    crrectOverTime() {
      this.appWay = 2;
    },
    // 加班时间arr
    getTimeArr() {
      this.timeArr = [];
      for (let i = 0; i <= 10; ) {
        this.timeArr.push(i);
        i += 0.5;
      }
    },
    //获取审核流程
    getApproveProcess() {
      this.$http
        .get("/Teams/Report/reportRecord.ashx", {
          params: {
            teamId: this.teamId,
            usId: this.id,
            date: this.day,
          },
        })
        .then((res) => {
          if (res.res == 0) {
            this.approvalProArr = res.data;
          }
        });
    },
    getCommentList() {
      this.commentDisabled = true;
      this.noMoreComment = true;
      this.$http
        .post("/Teams/Report/ReportCmt.ashx", {
          bid: this.bid,
          p: this.commentPage.page,
          pc: this.commentPage.count,
        })
        .then((res) => {
          if (res.res == 0) {
            this.commentDisabled = false;
            this.commentList = this.commentList.concat(res.data.data);
            if (this.commentList.length < res.data.page.TotalCount) {
              this.noMoreComment = false;
            }
          }
        });
    },
    moreComment() {
      if (!this.noMoreComment) {
        this.commentPage.page++;
        this.getCommentList();
      }
    },
    sendComment(params) {
      this.$http
        .post("/General/CmtPost.ashx", {
          ctn: params.ctn,
          FaId: params.parent.id,
          CommentType: params.CommentType,
          AudioSec: params.AudioSec,
          id: this.bid,
          type: 2,
        })
        .then((resp) => {
          if (resp.res == 0) {
            this.$refs.comment.success(resp.data);
          }
        });
    },
    handleCommand(command) {
      switch (command) {
        case "a": //快速提醒
          this.kpiType = 1;
          this.$modal.show("addReportKpi");
          break;
        case "b":
          this.kpiType = 2;
          this.$modal.show("addReportKpi");
          break;
        case "c":
          this.$modal.show("delayReportKpi");
          break;

        default:
          break;
      }
    },
  },
  created() {
    this.$E.$on("scShot", (res) => {
      if (res.type == 3) {
        if (this.activeReportType == 0) {
          this.normalWorkArr[this.activeSC].fileList.push({
            name: this.activeSC,
            url: this.$url + JSON.parse(res.imgUrl)[0].ImgUrl,
            link: JSON.parse(res.imgUrl)[0].ImgUrl,
          });
        } else {
          this.overWorkArr[this.activeSC].fileList.push({
            name: this.activeSC,
            url: this.$url + JSON.parse(res.imgUrl)[0].ImgUrl,
            link: JSON.parse(res.imgUrl)[0].ImgUrl,
          });
        }
      }
    });
  },
  mounted() {
    if (this.obj.id && this.obj.day && this.obj.teamId) {
      this.teamId = this.obj.teamId;
      this.id = this.obj.id;
      this.day = this.obj.day;
      this.referer = this.isMember
        ? "/report/memberReport"
        : "/report/myReport";
      this.referer += "/" + this.obj.teamId + "/" + this.id + "/" + this.day;
    } else {
      this.teamId = this.$route.params.teamId;
      this.id = this.$route.params.id;
      this.day = this.$route.params.date;
      this.referer = this.isMember
        ? "/report/memberReport"
        : "/report/myReport";
      this.referer += "/" + this.obj.teamId + "/" + this.id + "/" + this.day;
    }

    this.getTimeArr();
    if (this.teamId && this.id && this.day) {
      this.getDetail();
    }
    this.reportDetail = this.detail;
    window.onresize = () => {
      if (this.workChart_) {
        this.workChart_.resize();
      }
      if (this.overWorkChart_) {
        this.overWorkChart_.resize();
      }
    };
  },
};
