//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {
    XModal: () => import("@/components/XModal"),
  },
  computed: {
    cmurl() {
      return process.env.VUE_APP_CMURL;
    },
  },
  props: {
    WorkType: {
      type: Number,
      default: 0,
    },
    selReportTaskId: {
      type: Number,
      default: null,
    },
    date: {
      type: String,
      default: null,
    },
    uid: {
      type: Number | String,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      pageData: {
        pageIndex: 1,
        pageSize: 30,
        dataNum: 0,
      }, //分页
      picArray: [],
    };
  },
  methods: {
    opened() {
      this.$nextTick(() => {
        this.getData();
      });
    },
    /**
     * 获取数据
     */
    getData() {
      this.loading = true;
      const data = {
        task_id: this.selReportTaskId,
        date: this.date,
        page: this.pageData.pageIndex,
        pageCount: this.pageData.pageSize,
        uid: this.uid,
        WorkType: this.WorkType,
      };
      this.$http
        .post("/Work/Report/reportTaskScreenshot.ashx", data)
        .then((resp) => {
          if (resp.res == 0) {
            this.picArray = resp.data.Data;
            this.pageData.dataNum = resp.data.TotalCount;
          }
        })
        .finally(() => (this.loading = false));
    },
    handleSizeChange(val) {
      this.pageData.pageIndex = 1;
      this.pageData.pageSize = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.pageData.pageIndex = val;
      this.getData();
    },
  },
};
