//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    work: {
      type: Array,
      default: function() {
        return [];
      },
    },
    overwork: {
      type: Array,
      default: function() {
        return [];
      },
    },
    day: {
      type: String,
      default: "",
    },
    id: {
      type: Number | String,
      default: "",
    },
  },
  components: {
    workprogress: () => import("@/components/Progress"),
    XModal: () => import("@/components/XModal"),
  },
  data() {
    return {
      timeAxisTitle: "",
      photoLoading: false,
      progressPhotoArr: [],
      morePhoto: true,
      photoDis: true,
      photoItem: {},
      lastPhotoTime: "",
    };
  },
  computed: {
    cmurl() {
      return process.env.VUE_APP_CMURL;
    },
  },
  methods: {
    closeTimeAxis() {
      Object.assign(this.$data, this.$options.data());
    },
    getBarData(item) {
      if (item.name == "离线") {
        this.$message({
          type: "warning",
          message: "无法查看离线状态的详情",
        });
        return;
      }
      this.timeAxisTitle = item.name + "类进程截图";
      this.$modal.show("timeAxisPhoto");
      this.photoDis = true;
      this.photoLoading = true;
      this.photoItem = item;
      let params = {
        status: item.name,
        uid: this.id,
        stime: this.day,
        last: this.lastPhotoTime,
        pageCount: 15,
      };
      this.$http.post("/General/GetProcessImg.ashx", params).then((res) => {
        if (res.res == 0) {
          this.photoLoading = false;
          this.photoDis = false;
          if (res.data.length) {
            this.progressPhotoArr = this.progressPhotoArr.concat(res.data);
            this.lastPhotoTime = res.data[res.data.length - 1].time;
          } else {
            this.morePhoto = false;
            this.photoDis = true;
          }
        }
      });
    },
    loadMoreShot() {
      if (this.morePhoto) {
        this.getBarData(this.photoItem);
      }
    },
  },
};
