//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
	mapState
} from 'vuex';
export default {
	props: {
		//页面数据，需要固定格式
		pageData: {
			type: Array,
			default: function() {
				return [];
			}
		},
		//控制加载整个评论区域（通过外部控制）
		loadAll: {
			type: Boolean,
			default: false
		},
		//控制翻页加载的显示，值表示是否在翻页加载中
		onTurnPage: {
			type: Boolean,
			default: false
		},
		//是否数据已经加载完毕
		noMore: {
			type: Boolean,
			default: true
		},
		//是否禁用提交按钮
		commitLoading: {
			type: Boolean,
			default: false
		},
		evID: {
			type: Number,
			default: null
		}
	},
	model: {
		prop: 'pageData',
		event: 'upItem'
	},
	watch: {
		pageData: {
			handler(newval) {
				this.childVaule = newval;
			},
			immediate: true
		},
		childVaule: function(newval) {
			this.$emit('upItem', newval);
		}
	},
	components: {
		XFroala: () => import('@/components/XFroala'),
		XFroalaView: () => import('@/components/XFroalaView'),
		XAudio: () => import('@/components/Audio/H5'),
		Xlayer: () => import('@/components/Audio/player')
	},
	computed: {
		...mapState(['user']),
		disabled() {
			return this.onTurnPage || this.noMore;
		}
	},
	data() {
		return {
			commentCtn: null, //用户输入的内容
			isLoading: false, //控制加载整个评论区（通过内部控制）
			isSending: false, //是否在发送中
			parent: {
				name: null,
				id: null,
				index: null
			},
			CommentType: 0,
			audio: {
				link: null,
				duration: null
			},
			childVaule: [],
			photos: ''
		};
	},
	methods: {
		getAudio(audio) {
			this.audio.link = audio.url.link;
			this.audio.duration = audio.duration;
		},
		//发送@消息
		sendTip() {
			this.$refs.editorCmt.insertText();
		},
		//选择图片
		handleSuccess(response) {
			if (response.link) {
				let html = `<img src='${response.link}' style='width: 300px;' class='fr-fic fr-dii fr-draggable'>`;
				this.$refs.editorCmt.$refs.froala._editor.html.insert(html, true);
			}
		},
		beforUp(file) {
			let pattern = /image\/.*/;
			return pattern.test(file.type);
		},
		handleError(err) {
			this.$notify({
				type: 'error',
				message: '图片上传失败，请重试'
			});
		},
		//发生类型
		sendAudio(flag) {
			this.CommentType = flag;
		},
		// 提交评论 返回内容和层次信息
		sendComment() {
			if (this.CommentType == 0) {
				let temp = this.commentCtn;
				if (temp) {
					if (temp.indexOf("<img src=") == -1) {
						//tmp用于判断将标签排除后是否内容为空
						temp = temp.replace(/<(\S*?)[^>]*>.*?|<.*?\/>/g, '');
						temp = temp.replace(/&nbsp;/g, '');
						temp = temp.replace(/\s*/g, '');
					}
				}
				if (!temp) return;
			}
			if (this.CommentType == 1 && !this.audio.link) return;
			this.isLoading = true;
			this.isSending = true;
			const result = {
				CommentType: this.CommentType, //类型 0文本 1语音
				AudioSec: this.audio.duration, //语音时长
				ctn: this.CommentType == 0 ? this.commentCtn : this.audio.link, //评论类容
				parent: this.parent //回复的人
			};
			this.$emit('sendComment', result);
		},
		//回复成功的方法
		success(data) {
			this.isLoading = false;
			this.isSending = false;
			this.$notify({
				message: '评论成功',
				type: 'success'
			});
			if (this.parent.index != null) {
				let msg = {
					cmcId: data.COId, //id
					cmcName: this.user.name, //姓名
					cmcTime: data.CTime, //时间
					cmcMess: data.Mess, //内容
					cmComType: data.CommentType, //类型
					cmAudioSec: data.AudioSec //时长
				};
				//子
				this.childVaule[this.parent.index].cmChild.push(msg);
			} else {
				let msg = {
					cmId: data.COId, //id
					cmName: this.user.name, //姓名
					cmTime: data.CTime, //时间
					cmMess: data.Mess, //内容
					cmComType: data.CommentType, //类型
					cmAudioSec: data.AudioSec, //时长
					cmChild: []
				};
				//父
				this.childVaule.splice(0, 0, msg);
			}
			this.clear();
		},
		//无限加载方法
		turnPage() {
			//通知父组件执行翻页方法
			this.$emit('turnPage');
		},
		//清空评论区及回复对象
		clear(onlyParent = false) {
			this.parent = {
				name: null,
				id: null,
				index: null
			};
			this.audio = {
				link: null,
				duration: null
			};
			if (!onlyParent) {
				this.commentCtn = '';
			}
		},
		//回复的id名字
		changeCommentTarget(id, name, index) {
			this.parent.id = id;
			this.parent.name = name;
			this.parent.index = index;
		},
		//加载整个评论区（true开始加载，false停止加载）
		loading(flag) {
			this.loading = flag;
		},
		screenShot(command) {
			// this.$toClient('ScShot', {
			// 	hide: command == 0 ? true : false,
			// 	npic: false,
			// 	type:2
			// })
			let params = {
				hide: command == 0 ? true : false,
				npic: true,
				type: 2
			}
			this.$http.post('/Work/WrokAreas/NoticeUserInputScreenshots.ashx', params).then()
		}
	},
	created() {
		this.$E.$on('scShot', res => {
			if (res.type == 2) {
				let arr = JSON.parse(res.imgUrl)
				let html = ""
				if (arr.length) {
					html = `<img src=${this.$url+JSON.parse(res.imgUrl)[0].ImgUrl} style="width:200px"/>`
				} else {
					html = ""
					this.$message({
						type: 'warning',
						message: '截图失败'
					})
				}
				if (!this.commentCtn || this.commentCtn == null) {
					this.commentCtn = ""
				}
				this.commentCtn += html
			}
		})
	}
};
