//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	name: 'workAixs', //工作情况时间轴
	props: {
		day: {
			type: String,
			default: ''
		},
		id: {
			type: Number | String,
			default: ''
		},
		recordList: {
			type: Array,
			default: function() {
				return []
			}
		},
		upTime: {
			type: String,
			default: '--:--'
		},
		downTime: {
			type: String,
			default: '--:--'
		}
	},
	components: {
		XModal: () => import("@/components/XModal")
	},
	data() {
		return {
			timeAxisTitle: '',
			PName: '',
			activities: []

		}
	},
	watch: {
		recordList() {

		}
	},
	methods: {
		// 获得工作情况时间轴
		getWorkTimeAixs() {
			this.$http.get('/work/report/GetWorkStatusInHour.ashx', {
				params: {
					date: this.day,
					uid: this.id
				}
			}).then(res => {
				if (res.res == 0) {
					this.recordList = JSON.parse(res.data)
					console.log(this.recordList)
					this.recordList.forEach(item => {
						let maxThreeNum = []
						maxThreeNum.push(item.CORTime)
						maxThreeNum.push(item.ETTime)
						maxThreeNum.push(item.FreeTime)
						maxThreeNum.push(item.NotExistTime)
						maxThreeNum.push(item.UnknownTime)
						maxThreeNum = maxThreeNum.sort((a, b) => {
							return b - a
						})
						item.maxThreeNum = maxThreeNum.slice(0, 3)
						let names = []
						item.maxThreeNum.forEach(val => {
							if (val == 0) {
								return
							}
							if (item.CORTime == val) {
								names.push('工作: ' + val + '%')
							}
							if (item.ETTime == val) {
								names.push('娱乐: ' + val + '%')
							}
							if (item.FreeTime == val) {
								names.push('空闲: ' + val + '%')
							}
							if (item.UnknownTime == val) {
								names.push('未知: ' + val + '%')
							}
							if (item.NotExistTime == val) {
								names.push('离线: ' + val + '%')
							}
						})
						item.names = names
					})
					console.log(this.recordList)


				}
			})
		},
		//打开时间轴详情
		showTaskaxis(index, arr) {
			this.$modal.show('timeAxis')
			if (index == 1) {
				this.timeAxisTitle = '工作类进程列表'
			}
			if (index == 2) {
				this.timeAxisTitle = '空闲类进程列表'
			}
			if (index == 3) {
				this.timeAxisTitle = '娱乐类进程列表'
			}
			if (index == 4) {
				this.timeAxisTitle = '未知类进程列表'
			}
			this.activities = arr
			if (arr.length > 0) {
				this.PName = arr[0].PName
			}
		},
	},
	mounted() {}

}
