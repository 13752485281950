//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import XModal from '@/components/XModal';
export default {
	name: 'taskChoose',
	props: {
		isRadio: {
			//是否为单选
			type: Boolean,
			default: true
		},
		isSelf: {
			//是否只显示与自己相关
			type: Boolean,
			default: false
		},
		isSort: {
			//只显示有顺序的任务
			type: Boolean,
			default: false
		},
		userID: {
			//加载指定人的任务
			type: Number,
			default: null
		},
		reportId: {
			// type:Number
			// default:null
		},
		reportDate: {
			// type:String
			// default:null
		},
		modalName: {
			type: String,
			default: 'chooseTask'
		}
	},
	components: {
		XModal
	},
	data() {
		return {
			searchValue: '',
			loading: false,
			taskStatus: [],
			selTaskList: [], //获得所选任务
			taskList: this.$D.LIST('task_status'),
			priority: this.$D.LIST('g_priority'),
			chooseTypeList: [
				{
					type: 1,
					value: '我负责的任务'
				},
				{
					type: 0,
					value: '我创建的任务'
				},
				{
					type: 2,
					value: '我审核的任务'
				},
				{
					type: 3,
					value: '我监督的任务'
				},
				{
					type: 4,
					value: '我参与的任务'
				},
				{
					type: 5,
					value: '我关注的任务'
				}
			],
			isload: true,
			activeTask: '所有任务', //任务选择
			projectList: [], //项目列表
			searchProList: [], //搜索项目列表
			allProList: [], //所有项目列表
			perojectIndex: 0, //项目选择
			memberList: [], //成员列表
			taskClassList: [], //任务状态分类
			//查询参数
			projectID: '', //项目id
			queryType: '', //任务查询类型id
			jobClass: '', //任务分类
			jobStates: '', //任务状态
			frontmanId: '', //执行人id
			frontmanIdArr: '', //执行人id
			startTime: '', //开始时间
			endTime: '', //结束时间
			fir: '', //任务优先级
			keywords: '',
			orderByTime: 1, //是否按照创建时间排序
			inputSearch: '', //搜索框
			currentPage: 1, //任务接口当前页
			more: true,
			isLoading: false,
			isMoblie: false, //是否移动端
			tabIndex: 0, //移动端页面切换
			showDetail: false, //移动端详细搜索
			width: '',
			projectCurrentPage: 1, //项目分页
			axiosNum: 1,
			isLoadingPro: false,
			morePro: true,
			props: {
				multiple: true
			},
			tag: true,
			sortArr: [
				{
					value: '工作顺序',
					orderByTime: 1
				},
				{
					value: '创建时间',
					orderByTime: 2
				},
				{
					value: '结束时间',
					orderByTime: 3
				}
			]
		};
	},
	watch: {
		projectID() {
			if (this.projectID) {
				this.jobClass = '';
				this.taskClassList = [];
				this.getTaskStatusList();
			}
			this.taskList = [];
			this.currentPage = 1;
			this.getTasks();

			if (this.isRadio) {
				this.selTaskList = [];
			}
		},
		queryType() {
			if (this.isload) {
				this.currentPage = 1;
				this.taskList = [];
				this.getTasks();

				if (this.isRadio) {
					this.selTaskList = [];
				}
			}
		},
		jobClass() {
			this.currentPage = 1;
			this.taskList = [];
			if (this.isRadio) {
				this.selTaskList = [];
			}
			this.getTasks();
		},
		jobStates() {
			this.currentPage = 1;
			this.taskList = [];
			if (this.isRadio) {
				this.selTaskList = [];
			}
			this.getTasks();
		},
		frontmanIdArr() {
			this.currentPage = 1;
			this.taskList = [];
			if (this.isRadio) {
				this.selTaskList = [];
			}
			this.getTasks();
		},
		startTime() {
			this.currentPage = 1;
			this.taskList = [];
			if (this.isRadio) {
				this.selTaskList = [];
			}
			this.getTasks();
		},
		endTime() {
			this.currentPage = 1;
			this.taskList = [];
			if (this.isRadio) {
				this.selTaskList = [];
			}
			this.getTasks();
		},
		fir() {
			this.currentPage = 1;
			this.taskList = [];
			if (this.isRadio) {
				this.selTaskList = [];
			}
			this.getTasks();
		},
		orderByTime() {
			this.currentPage = 1;
			this.taskList = [];
			if (this.isRadio) {
				this.selTaskList = [];
			}
			this.getTasks();
		},
		width() {
			if (this.width < 991) {
				this.isMoblie = true;
			} else {
				this.isMoblie = false;
			}
		}
	},
	methods: {
		getTasks() {
			//默认获取全部任务
			this.loading = true;
			this.isLoading = false;
			let params = {
				projectID: this.projectID,
				queryType: this.queryType,
				jobClass: this.jobClass,
				jobStates: this.jobStates,
				frontmanId: this.frontmanIdArr.length == 0 ? '' : this.frontmanIdArr,
				fir: this.fir,
				startTime: this.startTime,
				endTime: this.endTime,
				orderByTime: this.orderByTime,
				inputSearch: this.keywords,
				currentPage: this.currentPage,
				hasSequence: this.isSort,
				bulletinTime: this.reportDate,
				bulletinUserID: this.reportId
			};
			if (!(this.reportDate && this.reportId)) {
				delete params.bulletinTime;
				delete params.bulletinUserID;
			}
			this.$http.post('/General/TaskSelector.ashx', params).then(res => {
				if (res.res == 0) {
					this.loading = false;
					this.taskList = this.taskList.concat(res.data.jobs);
					if (this.taskList.length >= res.data.page.TotalCount) {
						this.more = false;
					}
				}
			});
		},
		load() {
			this.isLoading = true;
			if (this.more) {
				this.currentPage++;
				setTimeout(() => {
					this.getTasks();
				}, 500);
			}
		},
		mobileLoad() {
			console.log(1);
		},
		loadPro() {
			this.isLoadingPro = true;
			if (this.morePro) {
				this.projectCurrentPage++;
				this.axiosNum++;
				setTimeout(() => {
					this.getProjectList();
				}, 500);
			}
		},
		getProjectList() {
			//获取项目列表
			this.isLoadingPro = false;
			this.$http
				.post('/General/GetProjectSelector.ashx', {
					// qxcs: 1,
					CurrentPage: this.projectCurrentPage,
					PageCount: 99999
				})
				.then(res => {
					if (res.res == 0) {
						this.allProList = this.allProList.concat(res.data.message);
						if (!(this.allProList[0].proId == '')) {
							this.allProList.unshift({
								proId: '',
								proName: '全部项目'
							});
						}
						if (this.allProList.length >= res.data.PageData.TotalCount) {
							this.morePro = false;
						}
						this.projectList = this.allProList;
					}
				});
		},
		getMemberList() {
			//获取成员列表
			this.$http.get('/General/GetUserAllGroupByReachName.ashx').then(res => {
				if (res.res == 0) {
					this.memberList = res.data;
					let arr = [];
					this.memberList.forEach(item => {
						let cc = [];
						item.user.forEach(val => {
							cc.push({
								value: val.UsId,
								label: val.Name
							});
						});
						arr.push({
							value: item.reach.RId,
							label: item.reach.Name,
							children: cc
						});
					});
					this.memberList = arr;
				}
			});
		},
		getTaskStatusList() {
			//获得任务状态分类
			this.$http
				.post('/Project/Job/taskGetClass.ashx', {
					projectID: this.projectID
				})
				.then(res => {
					if (res.res == 0) {
						this.taskClassList = res.data.jobClass;
					}
				});
		},
		toggleType(item) {
			//切换任务类型
			this.tabIndex++;
			this.isload = true;
			if (item == undefined) {
				this.activeTask = '所有任务';
				this.queryType = '';
				return;
			}
			this.activeTask = item.value;
			this.queryType = item.type;
		},
		toggleProject(index, id) {
			//切换项目
			this.perojectIndex = index;
			this.projectID = id;
			this.tabIndex++;
		},
		getSelTaskList() {
			//是否单选
			if (this.isRadio) {
				if (this.selTaskList.length > 1) {
					this.selTaskList = this.selTaskList.splice(-1, 1);
				}
			}
		},
		getCheckList() {
			//获取所选任务
			this.$modal.hide(this.modalName);
			this.$emit('getTaskList', this.selTaskList);
			this.taskList = [];
			this.selTaskList = [];
		},
		getSelTask() {
			// this.taskList[i].checked = !item.checked
			// //多选
			// if (!this.isRadio) {
			// 	if (item.checked) {
			// 		this.selTaskList.push(item)
			// 	} else {
			// 		for (let j = 0; j < this.selTaskList.length; j++) {
			// 			if (this.selTaskList[j].JId == item.JId) {
			// 				this.selTaskList.splice(j, 1)
			// 			}
			// 		}
			// 	}
			// }
			// if (this.isRadio) {
			// 	this.selTaskList.length--
			// }
		},
		findProject() {
			this.searchProList = [];
			if (this.searchValue == '') {
				this.projectList = this.allProList;
			} else {
				this.allProList.forEach(item => {
					if (item.proName.indexOf(this.searchValue) !== -1) {
						let bool = false;
						for (let i = 0; i < this.searchProList.length; i++) {
							if (this.searchProList[i].proId == item.proId) {
								bool = true;
								break;
							}
						}
						if (!bool) {
							this.searchProList.push(item);
						}
					}
				});
				this.projectList = this.searchProList;
			}
		},
		getId() {
			//获得执行人ID
			let arr = [];
			this.frontmanId.forEach(item => {
				arr.push(item[1]);
			});
			this.frontmanIdArr = arr;
		},
		// 只选择与自己相关的内容
		selfStatu() {
			if (this.isSelf) {
				for (let i = 0; i < this.chooseTypeList.length; i++) {
					if (this.chooseTypeList[i].type == 5) {
						this.chooseTypeList.splice(i, 1);
					}
				}
				this.activeTask = this.chooseTypeList[0].value;
				this.queryType = this.chooseTypeList[0].type;
			}
		},
		back() {
			//移动端返回
			if (this.tabIndex == 0) {
				return;
			}
			this.tabIndex--;
		},
		close() {
			this.$modal.hide(this.modalName);
		},
		beforeOpen() {
			this.taskList = [];
			this.selTaskList = [];
			this.width = document.documentElement.clientWidth;
			this.$nextTick(() => {
				if (this.userID) {
					this.frontmanIdArr = [];
					this.frontmanIdArr.push(this.userID);
				} else {
					this.selfStatu();
					this.getTasks();
				}
			});
			this.getMemberList();
			this.getProjectList();
			this.taskStatus = this.$D.LIST('task_status');
			this.isload = false;
		},
		closed() {
			this.taskList = [];
		},
		search() {
			this.currentPage = 1;
			this.taskList = [];
			if (this.isRadio) {
				this.selTaskList = [];
			}
			this.getTasks();
		}
	},
	beforeCreate() {
		this.taskStatus = this.$D.LIST('task_status');
	},
	mounted() {
		this.sortValue = this.sortArr[0].orderByTime;
		this.width = document.documentElement.clientWidth;
		window.onresize = () => {
			this.width = document.documentElement.clientWidth;
		};
	}
};
