//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";
import { imgChange } from "@/commons";
export default {
  props: {
    //是否显示截图
    IsRealTimeScreenshot: {
      type: Boolean,
      default: true,
    },
    indexData: {
      type: Object,
      default: () => {
        return null;
      },
    },
    teamValue: {
      type: Number,
      default: 0,
    },
    url: {
      type: String,
      default: null,
    },
    referer: {
      type: String,
      default: null,
    },
    modalName: {
      type: String,
      default: "taskM",
    },
  },
  components: {
    XModal: () => import("@/components/XModal"),
    CWinTmp: () => import("@/components/CWinTmp"),
  },

  data() {
    return {
      teamOptions: [],
      loading: false,
      editState: false,
      comWidth: "95%",
      attend: false,
      formData: {
        id: "", // 编辑窗口才用ID
        task: "",
        status: "",
        describe: "",
        CreatTime: "",
        teamValue: "",
        progress: [],
        userName: null,
        userPic: null,
        EstimatedTime: null,
        Schedule: 0,
      },
      Rules: {
        name: [
          {
            required: true,
            message: "请输入任务",
            trigger: "blur",
          },
          {
            min: 2,
            message: "至少2个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    ...mapState(["user"]),
    cmurl() {
      return process.env.VUE_APP_CMURL;
    },
    //当前任务或计划详情的状态对象
    status: function () {
      let obj = this.$D.ITEM("task_status", 1);
      let data = this.$D.ITEM("task_status", this.formData.Schedule);
      if (data) {
        obj = data;
      } else {
        obj.name = "未知";
      }
      return obj;
    },
    //任务详情中可供切换的任务状态
    statusOptions: function () {
      if ([4].indexOf(this.formData.status) >= 0) {
        return [99]; //只显示撤销申请
      } else {
        return [2, 7, 0, 3, 1]; //显示进行中、已完成、待定中、暂停的、未开始
      }
    },
  },

  methods: {
    changeStatus(val) {
      this.formData.Schedule = val;
    },
    //检查数字
    check(type, value) {
      if (value < 0) {
        this.formData[type] = null;
      } else {
        this.formData[type] = this.$toFixed(this.formData[type], 2);
      }
    },
    imgChange,
    /**
     * 获取团队
     */
    getTeams() {
      this.$http
        .get("/Teams/GetAllTeams.ashx", {
          params: { searchText: null, type: 2 },
        })
        .then((resp) => {
          if (resp.res == 0) {
            this.teamOptions = resp.data.filter(
              (m) => m.Functions.indexOf("任务管理") != -1
            );
          }
        });
    },
    opened() {
      this.$nextTick(() => {
        this.formData.teamValue = this.teamValue;
        this.getTeams();
      });
    },
    // 改变窗口状态的，搭配index页 添加和编辑按钮
    changeEditState() {
      var isOwner = false;
      var isNew = false;
      if (!this.indexData.row) {
        isNew = true;
        isOwner = true;
      } else if (this.user) {
        isOwner = this.indexData.row.UsId == this.user.UsId;
      }
      this.indexData.type = `${isNew ? "Add" : !isOwner ? "" : "Edit"}`;
      this.indexData.name = `${!isOwner ? "任务详情" : "编辑任务"}`;
      this.editState = this.indexData.type === "Add" ? true : false;
      if (this.indexData.row) {
        this.formData.task = this.indexData.row.Title;
        this.formData.status = this.indexData.row.Enable;
        this.formData.describe = this.indexData.row.Describe;
        this.formData.CreatTime = this.indexData.row.CreateTime;
        this.formData.progress = this.indexData.row.Taskhistorys;
        this.formData.userName = this.indexData.row.UserName;
        this.formData.userPic = this.indexData.row.Picture;
        this.formData.EstimatedTime = this.indexData.row.EstimatedTime;
        this.formData.Schedule = this.indexData.row.Schedule;
        this.formData.id = this.indexData.departmentCode;
      }
    },

    // 提交... 细节
    submiting() {
      if (this.formData.id) {
        this.$refs.winTmp.toggleComfirm();
      } else {
        this.$refs.winTmp.toggleSubmit();
      }
    },
    // 子组件提交共同的接口
    comSubmit() {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          this.submiting();

          if (!this.formData.id) {
            this.$http
              .post("/Task/AddTask.ashx", {
                teamId: this.formData.teamValue,
                title: this.formData.task,
                Describe: this.formData.describe,
                EstimatedTime: this.formData.EstimatedTime,
                Schedule: this.formData.Schedule,
              })
              .then((res) => {
                if (res.res == 0) {
                  this.$message({
                    message: `添加任务成功`,
                    type: "success",
                  });
                  this.submiting();
                  this.$modal.hide("taskM");
                  this.$emit("eventComfirm");
                } else {
                  this.submiting();
                }
              });
          } else {
            let params = {
              Id: this.formData.id,
              teamId: this.formData.teamValue,
              title: this.formData.task,
              describe: this.formData.describe,
              EstimatedTime: this.formData.EstimatedTime,
              Schedule: this.formData.Schedule,
            };
            this.$http
              .post("/Task/EditTask.ashx", params)
              .then((result) => {
                if (result.res == 0) {
                  this.$message({
                    message: `编辑任务成功`,
                    type: "success",
                  });
                  this.submiting();
                  this.$modal.hide("taskM");
                  this.$emit("eventComfirm");
                } else {
                  this.submiting();
                }
              })
              .catch(() => {});
          }
        } else {
          return false;
        }
      });
    },
    // 子组件事件3
    editClick() {
      this.$refs.editForm.clearValidate();
      if (this.editState === false) this.changeEditState();
    },

    // 关闭弹层前，重置数据
    beforeClose() {
      this.attend = false;
      this.loading = false;
      Object.assign(this.$data, this.$options.data());
      this.$router.replace({});
    },
    setLoading(val) {
      this.loading = val;
    },
  },
};
