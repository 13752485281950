var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"p-progress"}},[_c('div',{staticClass:"bars"},[_c('div',{staticClass:"bar newest"},[_c('div',{staticClass:"inner",style:('width:' + (_vm.newNum > 100 ? 100 : _vm.newNum) + '%')})]),_c('div',{staticClass:"bar current"},[_c('div',{staticClass:"inner",style:('width:' + (_vm.currentNum > 100 ? 100 : _vm.currentNum) + '%')})]),_c('div',{staticClass:"text-wrapper"},[_c('span',{staticClass:"inner-text",style:('left:' +
          (_vm.currentNum > 100
            ? '100%'
            : _vm.currentNum == 0
            ? '40px'
            : _vm.currentNum + '%'))},[_vm._v(_vm._s(_vm.currentNum)+"%")]),_c('span',{staticClass:"inner-text",style:('left:' +
          (_vm.newNum > 100 ? '100%' : _vm.newNum == 0 ? '40px' : _vm.newNum + '%'))},[_vm._v(_vm._s(_vm.newNum)+"%")]),_c('span',{staticClass:"inner-text last",style:('left:' +
          (_vm.lastNum > 100 ? '100%' : _vm.lastNum == 0 ? '40px' : _vm.lastNum + '%'))},[_vm._v(_vm._s(_vm.lastNum)+"%")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }