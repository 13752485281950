//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	props: ['BId', 'type', 'frontMan', "approveMan"], //任务ID,项目ID，任务分类ID

	components: {
		XModal: () => import('@/components/XModal'),
	},
	data() {
		return {
			form: {
				kpiId: null,
				leaveMessage: '',
				UserId:0
			},
			rule: {
				kpiId: [{
					required: true,
					message: '请选择要扣除的kpi项',
					trigger: 'blur'
				}], 
				userID:[{
					required: true,
					message: '请选择要考核的成员',
					trigger: 'blur'
				}]
				
				

			},
			kpiList: [],
			userArr:[]

		};
	},
	computed: {},
	methods: {
		//每次打开初始化
		init() {
			this.$nextTick(() => {
				Object.assign(this.$data, this.$options.data())
				this.getKpiList()
				this.userArr.push(
				this.approveMan[0].UId
				)
				this.userArr.push(
				this.frontMan.usId
				)
		   
			});
		},
		changeUser(){
		 // console.log(this.form.userID)	
		},
		getKpiList() {
			// debugger
			this.$http.post('/Work/Performance/KPISelectedByType.ashx', {
				Type: 3
			}).then(res => {
				if (res.res == 0) {
					this.kpiList = res.data
				}
			})
		},
		confirm() {
			this.$refs['form'].validate(valid => {
				if (valid) {
					this.$refs.addReportKpi.loadBtn(true);
					this.$http.post('/Work/Performance/SendNoticeKPIMess.ashx', {
						Type: 3,
						Kid: this.form.kpiId,
						SourceId: this.BId,
						content: this.form.leaveMessage,
						Operation: this.type,
						UserId: this.userArr[this.form.userID]
					}).then(res => {
						// console.log(res)
						if (res.res == 0) {
							this.$message({
								type: 'success',
								message: this.type == 1 ? '提醒成功' : '扣除成功'
							})
							this.$modal.hide('addReportKpi')
						}
						this.$refs.addReportKpi.loadBtn(false);
					})
				}
			})

		},

	},


};
