//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      viewType:1, //视图
      headerCellStyle: {
        textAlign: "center",
        fontSize: "18",
        background: "#f8f8f8",
        color: "#4a4a4a",
      },
      activeTitle: "",
      // activeIndex:0,
      dataList: [],
      keyPicLoading: false, //详情加载
      photoDis: false,
      photoLoading: false,
      last: "",
      morePhoto: true,
      rowKey: null,
      pname:null,
      wordLoading:false,
      tableList:[],
      sumTime:0, //总时长
      sumClick:0, //总点击次数
      sumFrequency:0 //评率总数
    };
  },
  props: {
    // tableList: {
    //   type: Array,
    //   default: function() {
    //     return [];
    //   },
    // },
    stime: {
      type: Date | String,
      default: "",
    },
    uid: {
      type: Number,
      default: null,
    },
    picHeighWord:{
      type:String,
      default:null
    },
    keyPname:{
      type:Array,
      default:null
    }
  },

  filters: {
    formateDecimal(num) {
      if (!num) {
        return 0;
      }
      num = parseFloat(num).toFixed(1);
      if (num[num.length - 1] == 0) {
        num = num.replace(/.0/, "");
        return num;
      }
      return num;
    },
  },
   computed: {
    cmurl() {
      return process.env.VUE_APP_CMURL;
    },
  },
  components: {
    XModal: () => import("@/components/XModal"),
    allSoftwareTable: () => import("./allSoftwareTable"),
  },
  methods: {
    /**
     * 视图切换
     */
    viewTypeChange(val){
      console.log(val)
    },
    closed(){
      this.$emit('heighWordClear')
      this.last=''
    },
    /**
     * 高频词弹窗
     */
    highWordsOpened(){
      this.$nextTick(()=>{
        this.wordLoading=true;
        const form = {
          usId: JSON.stringify([this.uid]),
          datestart: new Date(this.stime).timeFormat('yyyy-MM-dd 07:00:00'),
          dateend: new Date(this.stime).timeFormat('yyyy-MM-dd 23:59:00'),
          postId: '',
          reachId:'',
        };
        this.$http
          .get("/Work/ProcessStatistics.ashx", {
            params: form,
          })
          .then((resp) => {
            if (resp.res == 0) {
              this.tableList = resp.data.HighFrequencyWordss;
              this.sumTime=resp.data.sumTime;
              this.sumClick=resp.data.sumClick;
              this.sumFrequency=resp.data.sumFrequency
            }
          })
          .finally(() => (this.wordLoading= false));
          this.$refs.allSoftwareTable.getAllData();
      })
      
    },
    /**
     * 截图弹窗
     */
    opened(){
      this.$nextTick(()=>{
        this.dataList=[]
        if(this.picHeighWord){      
          this.pname=this.keyPname;
           this.rowKey = this.picHeighWord;
            this.activeTitle = this.picHeighWord + "的详情";
            this.photoLoading = true;         
            // this.getKeyPic();
        }
      })
    },
    loadMoreShot() {
      //加载工作条截图
      if (this.morePhoto && this.activeTitle) {
        this.getKeyPic();
      }
    },
    cellClick(row) {
      console.log(row)
      this.pname= row.FocusFormName;
      this.rowKey = row.Key;
      this.activeTitle = row.Key + "的详情";
      this.photoLoading = true;
      this.getKeyPic();
      if (this.activeTitle) {
        this.$modal.show("highWordsDes");
      }
    },
    /**
    获取关键词截图
     */
    getKeyPic() {
      this.keyPicLoading = true;
      this.photoDis = true;
      const sdate = new Date(this.stime).timeFormat('yyyy-MM-dd 00:00:01');
      const etime = new Date(this.stime).timeFormat('yyyy-MM-dd 23:59:59');
      const params = {
        uid: this.uid,
        stime: this.last?this.last:sdate,
        etime: etime,
        pageCount: 15,
        pname: this.pname.map((m)=> m.FocusFormName),
      };
      this.$http
        .post(
          "/General/GetProcessImgWithForm.ashx",
          params
        )
        .then((res) => {
          if (res.res == 0) {
            if (res.data.length>1 ||
              (res.data.length == 1 && !this.dataList.length)) {
              this.photoDis = false;
              this.dataList = this.dataList.concat(res.data);
              this.last = res.data[0].time;
              this.morePhoto = true;
            } else {
              this.morePhoto = false;
              this.photoDis = true;
            }
            this.keyPicLoading = false;
          }
        })
        .finally(() => (this.photoLoading = false));
    },
  },
  mounted() {
    document.addEventListener("keydown", (event) => {
      if (event.keyCode == 27) {
        if (this.$refs.highWords) {
          this.$modal.hide("highWordsDes");
          this.$modal.hide("highWords");
        }
      }
    });
  },
};
