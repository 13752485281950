//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    work: {
      type: Array,
      default: function() {
        return [];
      },
    },
    overwork: {
      type: Array,
      default: function() {
        return [];
      },
    },
    day: {
      type: String,
      default: "",
    },
    id: {
      type: Number | String,
      default: "",
    },
  },
  components: {
    workprogress: () => import("@/components/Progress"),
    XModal: () => import("@/components/XModal"),
  },
  computed: {
    cmurl() {
      return process.env.VUE_APP_CMURL;
    },
  },
  data() {
    return {
      timeAxisTitle: "",
      photoLoading: false,
      progressPhotoArr: [],
      morePhoto: true,
      photoDis: true,
      photoItem: {},
      lastPhotoTime: "",
    };
  },
  watch: {
    // work(newVal) {
    // 	if (newVal.length) {
    // 		this.drawWorkChart()
    // 	}
    // },
    // overwork(newVal) {
    // 	if (newVal.length) {
    // 		this.drawOverworkChart()
    // 	}
    // },
  },
  methods: {
    closeTimeAxis() {
      Object.assign(this.$data, this.$options.data());
    },
    getColor(params) {
      let background = "";
      let text = params.name;
      switch (text) {
        case "娱乐":
          background = "#ffef67";
          break;
        case "空闲":
          background = "#ff6565";
          break;
        case "未知":
          background = "#3bc8f1";
          break;
        case "离线":
          background = "#bbb";
          break;
        case "工作":
          background = "#73e8ed";
          break;
      }
      return background;
    },
    getBarData(item) {
      if (item.name == "离线") {
        this.$message({
          type: "warning",
          message: "无法查看离线状态的详情",
        });
        return;
      }
      this.timeAxisTitle = item.name + "类进程截图";
      this.$modal.show("workChartAxisPhoto");
      this.photoDis = true;
      this.photoLoading = true;
      this.photoItem = item;
      let params = {
        status: item.name,
        uid: this.id,
        stime: this.day,
        last: this.lastPhotoTime,
        pageCount: 15,
      };
      this.$http.post("/General/GetProcessImg.ashx", params).then((res) => {
        if (res.res == 0) {
          this.photoLoading = false;
          this.photoDis = false;
          if (res.data.length) {
            this.progressPhotoArr = this.progressPhotoArr.concat(res.data);
            this.lastPhotoTime = res.data[res.data.length - 1].time;
          } else {
            this.morePhoto = false;
            this.photoDis = true;
          }
        }
      });
    },
    loadMoreShot() {
      if (this.morePhoto) {
        this.getBarData(this.photoItem);
      }
    },
    formatParams(arr) {
      let _arr = arr.filter((item) => item.width > 0);
      _arr.forEach((item) => {
        item.value = item.width;
      });
      return _arr;
    },
    drawWorkChart() {
      //正常工作占比
      let option = {
        tooltip: {
          trigger: "item",
          formatter: "{b} : {c}%",
        },
        series: [
          {
            type: "pie",
            radius: "65%",
            center: ["50%", "50%"],
            animation: false,
            minAngle: 5,
            itemStyle: {
              normal: {
                color: (params) => {
                  return this.getColor(params);
                },
              },
            },
            data: this.formatParams(this.work),
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      this.$nextTick(() => {
        let chart = this.$echarts.init(document.getElementById("work-chart"));
        chart.setOption(option);
        chart.on("click", (params) => {
          params.event.event.stopPropagation();
          this.getBarData(params);
        });
      });
    },
    drawOverworkChart() {
      //正常工作占比
      let option = {
        tooltip: {
          trigger: "item",
          formatter: "{b} : {c}%",
        },
        series: [
          {
            type: "pie",
            radius: "65%",
            center: ["50%", "50%"],
            animation: false,
            minAngle: 5,
            itemStyle: {
              normal: {
                color: (params) => {
                  return this.getColor(params);
                },
              },
            },
            data: this.formatParams(this.overwork),
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      this.$nextTick(() => {
        let chart = this.$echarts.init(
          document.getElementById("overwork-chart")
        );
        chart.setOption(option);
        chart.on("click", (params) => {
          params.event.event.stopPropagation();
          this.getBarData(params);
        });
      });
    },
  },
  mounted() {
    if (this.work.length) {
      this.drawWorkChart();
    }
    if (this.overwork.length) {
      this.drawOverworkChart();
    }
  },
};
