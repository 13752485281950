//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
	props: ['frontMan', 'BId', 'approveMan'], //任务ID,项目ID，任务分类ID
	components: {
		XModal: () => import('@/components/XModal')
	},
	data() {
		return {
			form: {
				kpiId: null,
				score: null,
				leaveMessage: '',
				isAdd: null,
				endTime: '',
				userID: ''

			},
			rule: {
				kpiId: [{
					required: true,
					message: '请选择要扣除的kpi项',
					trigger: 'blur'
				}],
				score: [{
					required: true,
					message: '请选择要扣除的分值',
					trigger: 'blur'
				}]

			},
			kpiList: [],
			userArr: []

		};
	},
	watch: {
		'form.kpiId'(newVal) {
			this.kpiList.forEach(item => {
				if (item.Id == newVal) {
					this.form.score = item.Score
					this.form.isAdd = item.IsAdd
				}
			})
		}
	},
	computed: {},
	methods: {
		//每次打开初始化
		init() {
			this.$nextTick(() => {
				Object.assign(this.$data, this.$options.data())
				this.userArr.push(
					this.approveMan[0].UId
				)
				this.userArr.push(
					this.frontMan.usId
				)
				this.form.userID = 0
				this.getKpiList()
			});
		},
		getKpiList() {
			let arr=[]
			arr.push(this.userArr[this.form.userID])
			this.$http.post('/Work/Performance/userKPISelect.ashx', {
				Type: 1,
				UsId: arr,
				RType:3
			}).then(res => {
				if (res.res == 0) {
					this.kpiList = res.data
				}
			})
		},
		changeUser(){
		   this.getKpiList()	
		},
		getPersonInfo() {
			this.$http.get('/Person/GetPersonInfo.ashx').then(res => {
				if (res.res == 0) {
					this.form.kpiId = res.data.TaskLastKid
				}
			})
		},
		confirm() {
			this.$refs['form'].validate(valid => {
				if (valid) {
					this.$refs.delayReportKpi.loadBtn(true);
					this.$http.post('/Work/Performance/AddDelayKPIByJob.ashx', {
						KId: this.form.kpiId,
						BullId: this.BId,
						UsId: this.userArr[this.form.userID],
						Content: this.form.leaveMessage,
						IsAdd: this.form.isAdd,
						Score: this.form.score,
						EndTime: this.form.endTime
					}).then(res => {
						// console.log(res)
						if (res.res == 0) {
							this.$message({
								type: 'success',
								message: '添加成功'
							})
							this.$modal.hide('delayReportKpi')
						}
						this.$refs.delayReportKpi.loadBtn(false);
					})
				}
			})

		},


	},
	created() {

	}

};
