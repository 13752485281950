//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "timeLine",
  props: {
    id: {
      type: Number | String,
      default: "",
    },
    day: {
      type: String,
      default: "",
    },
    teamId: {
      type: Number | String,
      default: "",
    },
  },
  data() {
    return {
      timelineData: {},
    };
  },
  watch: {
    day() {
      this.getTimeLine();
    },
  },
  methods: {
    getTimeLine() {
      //获取简报时间条
      this.$http
        .post("/Teams/Report/GetUserTimeLine.ashx", {
          uid: this.id,
          date: this.day,
          teamId: this.teamId,
        })
        .then((res) => {
          if (res.res == 0) {
            this.timelineData = {};
            this.timelineData = res.data;
            this.$nextTick(() => {
              this.drawCanvas();
            });
          }
        });
    },
    getTimeLineColor(time) {
      let background = "";
      let value = time.name;
      switch (value) {
        case "工作":
          value = "工作";
          background = "#73e8ed";
          break;

        case "娱乐":
          value = "娱乐";
          background = "#ffef67";
          break;

        case "空闲":
          value = "空闲";
          background = "#ff6565";
          break;
        case "未知":
          value = "未知";
          background = "#3bc8f1";
          break;

        case "离线":
          value = "离线";
          background = "#ebebeb";
          break;
      }
      return background;
    },

    drawCanvas() {
      let canvas = document.getElementById("timeLine");
      if (canvas == null) {
        return;
      }
      canvas.width = 400;
      canvas.height = 500;
      let ctx = canvas.getContext("2d");
      let dpr = window.devicePixelRatio; // 假设dpr为2
      // 获取css的宽高
      let { width: cssWidth, height: cssHeight } =
        canvas.getBoundingClientRect();
      canvas.style.position = "absolute";
      canvas.style.left = "50%";
      canvas.style.top = "30px";
      canvas.style.marginLeft = "-" + cssWidth / 2 + "px";
      // 根据dpr，扩大canvas画布的像素，使1个canvas像素和1个物理像素相等
      canvas.style.width = canvas.width + "px";
      canvas.style.height = canvas.height + "px";
      canvas.width = dpr * cssWidth;
      canvas.height = dpr * cssHeight;
      // 由于画布扩大，canvas的坐标系也跟着扩大，如果按照原先的坐标系绘图内容会缩小
      // 所以需要将绘制比例放大
      ctx.scale(dpr, dpr);

      // console.log(ctx)
      ctx.clearRect(0, 0, document.getElementById("timeLine").width, 500);
      let num = 0;
      this.timelineData.jobs.forEach((item) => {
        ctx.strokeStyle = "#DDD";
        ctx.lineWidth = "2";
        ctx.textAlign = "center";
        // // 设置垂直对齐方式
        ctx.textBaseline = "middle";
        //左边
        if (num % 2 == 0) {
          ctx.beginPath();
          ctx.moveTo(170, (item.startRatio / 100) * 500 + 1);
          ctx.lineTo(180, (item.startRatio / 100) * 500 + 1);

          ctx.moveTo(175, (item.startRatio / 100) * 500);
          if ((item.endRatio / 100) * 500 >= 500) {
            item.endRatio = 98;
          }
          // console.log(item.startRatio / 100 * 500,item.endRatio / 100 * 500)
          ctx.lineTo(175, (item.endRatio / 100) * 500);

          ctx.moveTo(170, (item.endRatio / 100) * 500);
          ctx.lineTo(180, (item.endRatio / 100) * 500);

          ctx.moveTo(
            175,
            ((item.startRatio + (item.endRatio - item.startRatio) / 2) / 100) *
              500
          );
          ctx.lineTo(
            145,
            ((item.startRatio + (item.endRatio - item.startRatio) / 2) / 100) *
              500
          );
          ctx.closePath();
          ctx.font = "13px normal PingFang SC";
          // // 设置颜色
          ctx.fillStyle = "#333 ";
          if (item.name.length > 15) {
            item.name = item.name.slice(0, 15) + "...";
          }
          let textY =
            ((item.startRatio + (item.endRatio - item.startRatio) / 2) / 100) *
              500 -
            10;
          if (textY < 0) {
            textY = 8;
          }
          ctx.fillText(item.name, 90, textY);
          ctx.font = "12px normal PingFang SC";
          // // 设置颜色
          ctx.fillStyle = "#6D6D6D ";
          ctx.fillText(
            item.startTime.timeFormat("HH:mm") +
              "-" +
              item.endTime.timeFormat("HH:mm"),
            92,
            textY + 20
          );

          ctx.stroke();
        } else {
          //右边
          ctx.beginPath();
          ctx.moveTo(220, (item.startRatio / 100) * 500);
          ctx.lineTo(230, (item.startRatio / 100) * 500);

          ctx.closePath();
          ctx.moveTo(225, (item.startRatio / 100) * 500);
          if ((item.endRatio / 100) * 500 >= 500) {
            item.endRatio = 98;
          }
          ctx.lineTo(225, (item.endRatio / 100) * 500);
          ctx.closePath();
          ctx.moveTo(220, (item.endRatio / 100) * 500);
          ctx.lineTo(230, (item.endRatio / 100) * 500);
          ctx.closePath();
          ctx.moveTo(
            225,
            ((item.startRatio + (item.endRatio - item.startRatio) / 2) / 100) *
              500
          );
          ctx.lineTo(
            255,
            ((item.startRatio + (item.endRatio - item.startRatio) / 2) / 100) *
              500
          );
          ctx.closePath();
          ctx.font = "13px normal PingFang SC";
          // // 设置颜色
          ctx.fillStyle = "#333";
          if (item.name.length > 15) {
            item.name = item.name.slice(0, 15) + "...";
          }
          let textY =
            ((item.startRatio + (item.endRatio - item.startRatio) / 2) / 100) *
              500 -
            10;
          if (textY < 0) {
            textY = 8;
          }

          ctx.fillText(item.name, 310, textY);
          ctx.font = "12px normal PingFang SC";
          // // 设置颜色
          ctx.fillStyle = "#6D6D6D";
          ctx.fillText(
            item.startTime.timeFormat("HH:mm") +
              "-" +
              item.endTime.timeFormat("HH:mm"),
            310,
            textY + 20
          );
          ctx.stroke();
        }

        num++;
      });
    },
  },
  // mounted(){
  // 	this.getTimeLine()
  // }
};
