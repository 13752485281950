//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    lastNum: {
      //上次进展
      type: String | Number,
      default: 0,
    },
    currentNum: {
      //本次进展
      type: String | Number,
      default: 0,
    },
    newNum: {
      //最新进展
      type: String | Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
};
